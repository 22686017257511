import React from 'react';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import { getLocalToken } from "../../utils";

const colourOptions = [
  {value: "PROSPECTO", label: 'Prospecto'},
  {value: "CLIENTE", label: 'Cliente'},
  {value: "DESISTIDO", label: 'Desistido'},
  {value: "INACTIVO_POR_PAGO", label: 'Inactivo por pago'},
];

const filterColors = (inputValue) => {
  return colourOptions.filter(i =>
    i.label.toLowerCase().includes(inputValue.toLowerCase())
  );
};

const loadOptions = (inputValue, callback) => {
  setTimeout(() => {
    callback(filterColors(inputValue));
  }, 1000);
};


export default function WithPromises(props) {
  const {placeholder, url, callback, defaultOptions, autoFocus, isSearchable, select_value, defaultValue, isClearable, isPromiseOptions = true, isMulti } = props;

  function handleChange(value) {
    const temp = isMulti ? value : {...value}; // Si es multi retorna un array con los valores
    // delete temp['label'];
    // delete temp['value'];
    ( callback && callback(temp))
  }

  const promiseOptions = inputValue =>
    new Promise(resolve => {
      axios.get(`${url}?text=${inputValue}`, {
        headers: {
          'content-type': 'application/json',
          'Authorization': 'Token ' + getLocalToken()
        },
      })
      .then(function (response) {
        const data = response.data.map(item => {
          return {
            ...item,
            value: item.id || item.id_plan,
            label: item.nombre || item.name,
          }
        });
        resolve(data);
      })
      .catch(function (error) {
        console.log(url, "error", error);
      });
    });
  

  return (
    <AsyncSelect
      isMulti={isMulti}
      isClearable={isClearable}
      defaultValue={defaultValue ? defaultValue : undefined}
      value={select_value ? select_value : undefined}
      isSearchable={isSearchable}
      autoFocus={autoFocus}
      cacheOptions
      defaultOptions={defaultOptions}
      placeholder={placeholder}
      loadOptions={isPromiseOptions ? promiseOptions : loadOptions}
      styles={{ 
        menu: base => ({ ...base, zIndex: 9999 }),
        control: base => ({ ...base, marginTop: 8, paddingTop: 4, marginBottom: 8, }),
      }}
      onChange={handleChange}
    />
  );
}