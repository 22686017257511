import React, { useRef, useEffect } from 'react';
import { useParams } from "react-router-dom";
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { green } from '@material-ui/core/colors';

import md5 from 'md5';

import LogoSoftsegurosImg from '../../img/logo_softseguros.png';
import OfficeEquipmentImg from '../../img/office_equipment.png';
import {currencyFormat} from '../../utils';

import Divider from '@material-ui/core/Divider';

const theme = createMuiTheme({
  palette: {
    primary: green,
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    padding: 0
  },
  img:{
    width: '80%',
    padding: 24,
  },
  media:{
    width: '80%',
    padding: 24,
  },
  text:{
    color: "white",
    fontWeight: 'bold',
  }
}));

const CreateForm = (
  merchantId,
  accountId,
  description,
  referenceCode,
  amount,
  tax,
  taxReturnBase,
  currency,
  signature,
  test,
  buyerEmail,
  payerFullName,
  responseUrl,
  confirmationUrl,
) => {

};

export default function SimpleContainer() {

  var { data } = useParams();

  var purchase = {};

  try {
    // purchase = JSON.parse(atob(decodeURIComponent(data)))
    purchase = JSON.parse(decodeURIComponent(escape(atob(data))));
  } catch(e) {
    console.log("e", e);
  }

  const classes = useStyles();
  const ButtonEl = useRef(null);
  const FormEl = useRef(null);
  
  useEffect(() => {
    setTimeout(function(){
      if(purchase.send_form){
        ButtonEl.current.click()
      }
    }, 3000);
  });

  const merchantId = process.env.REACT_APP_PAYU_MERCHANTID;
  const accountId = process.env.REACT_APP_PAYU_ACCOUNTID;
  const ApiKey = process.env.REACT_APP_PAYU_APIKEY;

  const signature = md5(`${ApiKey}~${merchantId}~${purchase.referenceCode}~${purchase.amount}~${purchase.currency}`);

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="sm">
      <Toolbar variant="dense" />

          <Card className={classes.root}>

            { purchase.send_form && <div style={{
              position: "absolute",
              right: 0, left: 0, top: 0, bottom: 0,
              background: 'transparent', zIndex: 1,
            }}/>
              }
            <div style={{width: '100%', margin: '0 auto', background: "#4077ba"}}>
              <CardMedia
                style={{width: '50%', margin: '0 auto', }}
                component="img"
                classes={{
                  img: classes.img,
                }}
                src={LogoSoftsegurosImg}
                title="Logo Softseguros"
              />
            </div>

            <CardContent style={{paddingBottom: 0, textAlign: 'center'}}>
              <img src={OfficeEquipmentImg} alt="imagen Softseguros" />
            </CardContent>

            <CardContent style={{paddingBottom: 0}}>
              <Typography variant="h5" align="center" component="h2" style={{ fontWeight: 'bold', }}>{purchase.title}</Typography>
            </CardContent>

            { purchase.description && <CardContent style={{paddingBottom: 0}}>
              <Typography variant="body2" color="textSecondary" component="p">{purchase.description}</Typography>
            </CardContent> }
              
            { purchase.products && <CardContent style={{paddingBottom: 0}}>
              <Grid container alignContent="center" alignItems="center" justify="center">
                <Grid item xs={7} >
                  <Divider style={{marginBottom: 16}}/>
                    {  purchase.products.map((item, key)=>{
                      return (<Grid container spacing={0} key={key}>
                        <Grid item xs={8} align="center">
                          <Typography variant="body1" color="textPrimary" component="label" >{item.label}:</Typography>
                        </Grid>
                        <Grid item xs={4} align="center">
                          <Typography variant="body1" color="textSecondary" component="label" >{item.amount ? item.amount : "\u221e"}</Typography>
                        </Grid>
                      </Grid>)
                    }) }
                  <Divider style={{marginTop: 16}}/>
                </Grid>
              </Grid>
            </CardContent> } 

            <CardContent style={{textAlign: 'center', paddingBottom:0}}>
              <Typography variant="h5" align="center" style={{ fontWeight: "bold", }}>Valor a pagar:</Typography>
              <Typography variant="h5" align="center"
                style={{ fontWeight: 200, }}
              >{ currencyFormat(purchase.amount || 0, purchase.currency=="COP" ? 2 : 0) } {purchase.currency}
              </Typography>
            </CardContent>

            <CardContent>

              <form id="form-payment-link" ref={FormEl} method="post" action="https://checkout.payulatam.com/ppp-web-gateway-payu/">
                <input name="merchantId"    type="hidden"  value={merchantId}   />
                <input name="accountId"     type="hidden"  value={accountId} />
                <input name="description"   type="hidden"  value={purchase.title}  />
                <input name="referenceCode" type="hidden"  value={purchase.referenceCode} />
                <input name="amount"        type="hidden"  value={purchase.amount}   />
                <input name="tax"           type="hidden"  value="0"  />
                <input name="taxReturnBase" type="hidden"  value="0" />
                <input name="currency"      type="hidden"  value={purchase.currency} />
                <input name="signature"     type="hidden"  value={signature}  />
                <input name="test"          type="hidden"  value="0" />

                {/* <input name="buyerEmail"    type="hidden"  value="josorio@tecmovin.com" /> */}
                {/* <input name="payerFullName"    type="hidden"  value="Julian Stiven Osorio Escobar" /> */}
                
                <input name="responseUrl"    type="hidden"  value={purchase.responseUrl} />
                <input name="confirmationUrl"    type="hidden"  value={purchase.confirmationUrl} />
              </form>
              
              { purchase.amount && <ThemeProvider theme={theme}>
                <Button type="Submit" variant="contained" color="primary" size="large" form="form-payment-link"
                  style={{ borderRadius: 50, display: purchase.send_form ? "none" : "block" }}
                  className={classes.text}
                  ref={ButtonEl}
                  fullWidth
                >Pagar</Button> </ThemeProvider>
              }

            </CardContent>
          </Card>
          { purchase.send_form && <LinearProgress /> }
      </Container>
    </React.Fragment>
  );
}