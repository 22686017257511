import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
  checkbox: {
    display: 'None',
  },
  container:{
    padding:2,
    border: 0,
    width:'100%',
    height:40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: 'hsl(0,0%,80%)',
    borderRadius: '4px',
    borderStyle: 'solid',
    borderWidth: '1px',
  },
  activo:{
    backgroundColor: 'rgb(240, 247, 255)'
  },
  inactivo:'gray'
}));

const SiOrNO = (props)=>{
  const [state, setState] = React.useState({
    active: props.state || false,
  });
  const classes = useStyles();
  const handleChange = (event) => {
    if (props.disable){
      return
    }

    props.callback(props.name, event.target.checked)
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const { active } = state;
  return (
    <FormControlLabel
      className={`${classes.container} ${active ? classes.activo: classes.inactivo}`}
      control={
        <Checkbox disabled={props.disable} className={classes.checkbox} checked={active} onChange={handleChange} name="active" />
      }
      label={active ? "Si" : "No"}
    />
  )
}

export default SiOrNO;