import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import NotificationsIcon from '@material-ui/icons/Notifications';


import Typography from '@material-ui/core/Typography';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";

import ApiPost from '../../../api/Post';
import ApiGet from '../../../api/Get';
import {ConvertDate, formatDate} from '../../../utils';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  dialogContentText:{
    textAlign: "center",
    paddingTop: theme.spacing(2)
  },
  dialogActions:{
    justifyContent: 'center',
  },
  typographyTitle:{
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
    textAlign: 'center',
    paddingBottom: theme.spacing(2)
  },
  chip:{
    marginTop: -8,
  }
}));

export default function AlertDialog(props) {
  const classes = useStyles();

  const [observations, setObservations] = React.useState([]);
  const [description, setDescription] = React.useState("");
  const [selectedDate, handleDateChange] = React.useState(null);

  function getList(){
    if(!props.marca_id){ return }
    
    ApiGet.listCustomerObservations({
      marca_id: props.marca_id
    })
    .then(r => {
      setObservations(r);
    }).catch(e => {
      console.log("e", e);
    });
  }

  React.useEffect(() => {
    getList();
  }, []);

  const handleSaveCustomerObservations = (event) => {

    event.preventDefault();
    const date = formatDate(selectedDate);

    const data = {
      marca_id: props.marca_id,
      description: description,
      alert_date: date,
    };

    if( props.marca_id ){
      ApiPost.saveCustomerObservations(data)
      .then(function(response) {
        props.callback(description);
        setDescription("");
        handleDateChange(null);
        getList();
      })
      .catch(function(error) {
        console.log("error", error);
      });

    }else{
      props.callback(data);
    }

  };

  // const handleDateChange = (name, date)=>{

  //   let value = formatDate(date);
    
  //   console.log("value", value);

  //   if(!value){ return; }

  //   // ApiPut.updateMarca(row.id, {
  //   //   entry_date: value,
  //   // })
  //   // .then(function(response) {
  //   //   setRow({...row, entry_date: value})
  //   // })
  //   // .catch(function(error) {
  //   //   console.log("error", error);
  //   // });

  // }

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      fullWidth={true}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      >

      <DialogTitle id="alert-dialog-title">

        <Typography className={classes.typographyTitle} gutterBottom>
          { props.marca_id ? "Listado de observaciones de " + props.marca_name : "Agregar observación masivamente" }
        </Typography>

        <form autoComplete="off" onSubmit={handleSaveCustomerObservations}>
          <TextField
            required
            id="outlined-basic"
            label="Observación"
            variant="outlined"
            multiline
            rows="2"
            rowsMax="4"
            fullWidth
            autoFocus
            value={description}
            onChange={(event)=>{setDescription(event.target.value)}}
          />

          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <KeyboardDatePicker
              autoOk
              value={selectedDate}
              disableToolbar
              variant="inline"
              inputVariant="outlined"
              className={classes.customWidthDate}
              format="yyyy-MM-dd"
              placeholder=""
              fullWidth
              margin="dense"
              placeholder="Fecha alerta"
              onChange={date => handleDateChange(date)}
              // onChange={date => handleDateChange("entry_date", date)}
            />
          </MuiPickersUtilsProvider>

          <Button type="submit" variant="outlined" color="primary" className={classes.button} >
            Agregar
          </Button>
        </form>

        <Divider/>
        
      </DialogTitle>

      <DialogContent>

        <List className={classes.root}>
          {observations.map( (row, index) => (
            <React.Fragment key={index}>
              <ListItem key={index}>
                {/* <ListItemAvatar>
                  <Avatar>
                    JG
                  </Avatar>
                </ListItemAvatar> */}
                <ListItemText
                  primary={
                    <React.Fragment>
                      { row.alert_date && ( <Chip className={classes.chip} size="small" icon={<NotificationsIcon />} label={ row.alert_date } /> ) } &nbsp;
                      {row.description}
                    </React.Fragment>
                  }
                  secondary={
                    <React.Fragment>
                      { formatDate(row.created_at) } - <ConvertDate seconds={ row.seconds_created_at} /> - { row.full_name }
                    </React.Fragment>
                  }
                  />
              </ListItem>
              <Divider component="li" />  
            </React.Fragment>
            ))}
        </List>

        {/* <DialogContentText id="alert-dialog-description">
          Let Google help apps determine location. This means sending anonymous location data to
          Google, even when no apps are running.
        </DialogContentText> */}

      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={props.handleClose} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
