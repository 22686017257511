import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from "react-router-dom";

import {
  AreaChart, Area, YAxis, ResponsiveContainer
} from 'recharts';

const width = 198;
const height = 152;

const useStyles = makeStyles(theme => ({
  button:{
    margin: theme.spacing(1),
  },
  card: {
    position: 'relative',
    minWidth: width,
    height: height,
    minHeight: height,
    textAlign: 'center',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 14,
    color: '#818ea3',
    letterSpacing: '.0625rem',
  },
  pos: {
    marginTop: 12,
    // marginBottom: 12,
  },
  cardContent:{
    position: 'relative',
    zIndex: 2,
    height: "inherit",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  areaChart:{
    position: 'absolute !important',
    bottom: 0,
    opacity: .5,
    zIndex: 1,
  }
}));

export default function SimpleCard(props) {

  const {title, subtitle, value = null, ids} = props;
  // const value = Math.floor(Math.random()*(0-10+1)+10);
  // const {title, subtitle} = props;

  const classes = useStyles();
  const data = [
    // {name: 0, uv: 0},
    // {name: value, uv: value*100/10},
    // {name: 0, uv: 0},

    // {name: 0, uv: 0},
    // {name: 100-(value*100/10), uv: 100-(value*100/10)},
    // {name: value*100/10, uv: value*100/10},

    {name: 0, uv: 0},
    {name: value*100/10, uv: value*100/10},

  ];

  return (
    <Link to={{ pathname: '/customers',  customer_ids: ids }} style={{textDecoration: 'none'}} disabled>
      <Card className={classes.card}>

        <CardContent className={classes.cardContent}>


          <Typography className={classes.title} color="textPrimary" gutterBottom>
            {title}
          </Typography>
          <Typography variant="h4">
            { value != null ? value : <CircularProgress className={classes.CircularProgress} size={20}/> }
          </Typography>
          <Typography className={classes.pos} variant="body2" component="p" color="textSecondary">
            {subtitle}
          </Typography>
        </CardContent>
        {/* <CardActions>
          <Button size="small">Learn More</Button>
        </CardActions> */}
        <ResponsiveContainer className={classes.areaChart}>
          <AreaChart
            
            width={width}
            height={152.9}
            data={data}
            margin={{
              top: 0, right: 0, left: 0, bottom: 0,
            }}
          >
            <Area type="monotone" strokeWidth={1} dataKey="uv" stroke="transparent" fill="#ffa1b48f" />
            {/* <Area type="monotone" strokeWidth={1} dataKey="uv" stroke="transparent" fill="#7ebbfe" /> */}
            <YAxis type="number" domain={[0, 100]} hide/>
          </AreaChart>
        </ResponsiveContainer>
      </Card>
    </Link>
  );
}
