import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import HelpIcon from '@material-ui/icons/Help';
import CvcImg from '../../img/cvc.svg';

const emails = ['username@gmail.com', 'user02@gmail.com'];
const useStyles = makeStyles({
  root: {
    textAlign: 'center', 
  },
  img:{
    padding: 12,
  }
});

function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} className={classes.root}>
      <DialogTitle id="simple-dialog-title">¿Que es el CVC?</DialogTitle>
      <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Código de seguridad de 3 ó 4<br/>Digitos encontrado en la parte de<br/> atrás de tu tarjeta.

          </DialogContentText>
          <img src={CvcImg} className={classes.img} alt="cvc" width="50%"/>
          <DialogActions>
            <Button onClick={handleClose} variant="contained" color="primary" fullWidth>
              ok
            </Button>
          </DialogActions>
        </DialogContent>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function SimpleDialogDemo(props) {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = value => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <div>
      <HelpIcon onClick={handleClickOpen} {...props} style={{cursor: 'pointer'}}/>
      <SimpleDialog selectedValue={selectedValue} open={open} onClose={handleClose} />
    </div>
  );
}
