import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { useDispatch, useSelector } from "react-redux";
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import ChangeStateOrStage from './menu/ChangeStateOrStage';
import DialogApplyRenewal from './menu/DialogApplyRenewal';
import DialogObservations from './menu/DialogObservations';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 2),
  },
  button:{
    textTransform: 'none',
  },
  menu:{
    '& button':{
      textTransform: 'none',
      textAlign: 'left',
      justifyContent: 'left',
    }
  }
}));

export function CheckboxReduxLabel(props) {
  const classes = useStyles();
  const [openDialogObservations, setOpenDialogObservations] = React.useState(false);

  const {length, list_name} = props;

  const dispatch = useDispatch();
  
  const label = useSelector(state =>{
    const {tables} = state;
    let value;

    if(tables[list_name].all){
      value = length - tables[list_name].ids.length;
    }else{
      value = tables[list_name].ids.length;
    }

    return value ? value : 0;
  });

  const list = useSelector(state =>{
    const {tables} = state;
    return tables[list_name];
  });

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleActionsGetList = (data) =>{
    dispatch({ type: "REMOVE_ALL", table_name: list_name });
    props.handleActionsGetList({
      action: JSON.stringify({
        name: "MASSIVE_CHANGES",
        ...data,
        ...list
      })
    });
  }

  function handleClickOpenDialogObservations() {
    handleClose();
    setOpenDialogObservations(true);
  }
  function handleCloseDialogObservations() {
    setOpenDialogObservations(false);
  }

  return (
    <div className={classes.root}>
      <Button size="small" variant="outlined" color="primary" className={classes.button} disabled={!label} onClick={handleClick}> {label || 0 } Seleccionados</Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.menu}
      >
        <MenuItem>
          <ChangeStateOrStage onClose={handleClose} label={"Cambiar de estado o etapa"}
            handleGetList={(data)=>{handleActionsGetList({...data, action_name:"CHANGE_STAGE_STATE"})}}
          />
        </MenuItem>

        <MenuItem onClick={handleClickOpenDialogObservations}>
          Agregar observación
        </MenuItem>
        
        <MenuItem>
          <DialogApplyRenewal onClose={handleClose}
            handleGetList={(data)=>{handleActionsGetList({...data, action_name:"RENEWAL_INCREMENT"})}}
          />
        </MenuItem>

        { list_name=="customer_list"
          && <MenuItem onClick={()=>{
            handleActionsGetList({action_name:"CREATE_AUTOMATIC_DEBIT_USER"})
            handleClose();
          }}>
            Crear user débito automático
          </MenuItem>
        }

      </Menu>

      { openDialogObservations && <DialogObservations
        open={openDialogObservations}
        handleClose={handleCloseDialogObservations}
        callback={(data)=>{
          handleCloseDialogObservations();
          handleActionsGetList({...data, action_name:"ADD_OBSERVATION"});
        }}
      />}

    </div>
  );
}

export default function CheckboxRedux(props) {

  const { all, id, list_name } = props;

  let type = all ? "ALL_CHECKED" : "ADD_ID";

  const checked = useSelector(state =>{
    const {tables} = state;

    if(id){

      if(tables[list_name].all){
        return tables[list_name].all && !tables[list_name].ids.includes(id);
      }

      return tables[list_name].ids.includes(id);
    }    

    return tables[list_name].all;
  });

  const dispatch = useDispatch();
  
  const handleOnChange = (data) => {
    if(all){
      dispatch({ table_name: list_name, type: type });
    }else if(id){
      dispatch({ table_name: list_name, type: type, value: id });
    }
  }

  return (
    <Checkbox
      checked={checked}
      onChange={handleOnChange}
      color="primary"
      value="checkedA"
      style={{padding: 0}}
    />
  );
}