import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import AsyncSelect from '../selectApi';

const stateDefault = {
  state_id: null,
  stage_id: null,
};

export default function ActionsDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState(stateDefault);

  const { label, handleGetList } = props;

  const handleClickOpen = () => {

    if(props.onClose){
      props.onClose();
    }
    
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <span onClick={handleClickOpen}>{label}</span>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{style: {overflowY: 'visible'}}}
      >
        <DialogTitle id="alert-dialog-title">{"Cambiar de estado o etapa de forma masiva"}</DialogTitle>
        <DialogContent style={{ overflowY: 'visible' }}>

          <AsyncSelect
            placeholder="Estado"
            defaultValue={null}
            url={`${process.env.REACT_APP_API_URL}/clientstate/`}
            callback={(value)=>{ setData({...data, state_id: value.id}) }}
            defaultOptions={true}
            isSearchable={false}
          />

          <AsyncSelect
            placeholder="Etapa"
            defaultValue={null}
            url={`${process.env.REACT_APP_API_URL}/clientstage/`}
            callback={(value)=>{ setData({...data, stage_id: value.id}) }}
            defaultOptions={true}
            isSearchable={false}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            cerrar
          </Button>
          <Button onClick={()=>{
            setData(stateDefault);
            handleClose();
            handleGetList(data);
          }} color="primary" autoFocus>
            guardar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}