import React from 'react';
import { ThemeProvider, createMuiTheme, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import PersonIcon from '@material-ui/icons/Person';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import DialpadIcon from '@material-ui/icons/Dialpad';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

import { ReactComponent as CrossIcon } from '../../img/cross.svg';
import { ReactComponent as CreditCardIcon } from '../../img/credit-card.svg';
import { ReactComponent as CalendarIcon } from '../../img/calendar.svg';

import LogoSoftsegurosImg from '../../img/logo_softseguros.png';
import EpaycoPagoSeguroImg from '../../img/epayco_pago_seguro.png';
import PagoAUnClickImg from '../../img/pago_a_un_click.png';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';

import Lottie from '../../lottie';
import apiPost from '../../api/Post';
import DialogWhatsCvc from './dialog-whats-cvc';

const useStyles = makeStyles(theme => ({
	root: {
		height: "100vh",
	},
	paper: {
		display: 'flex',
		flexWrap: 'wrap',
		padding: theme.spacing(2),
    margin: theme.spacing(2),
	},
  button: {
    margin: theme.spacing(2, 0),
    textTransform: 'none',
  },
  typography:{
    // padding: theme.spacing(1),
  	width: '100%',
  },
  textField: {
    marginTop: theme.spacing(1),
    "& svg": {
      color: 'gray',
    },
    "& .Mui-focused svg": {
      color: '#2196F3',
      width: '1em',
    },
    "& input:required:valid ~ fieldset": {
      background: '#2196F30D',
      borderColor: '#2196F3',
      borderWidth: 2,
    },
  },
  marginGrid:{
    paddingBottom: `0px !important`,
    margin: `0px !important`,
  },
  contentImages:{
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
    textAlign: 'center',
  },
	img:{
    width: '100%',
  },
  contentFooter:{
		marginTop: theme.spacing(2),
    textAlign: "center",
  },
  footerImg:{
    width: '80%',
  },
  iconInput:{
    paddingRight: theme.spacing(1),
  },
  customSvgIcon:{
    paddingRight: theme.spacing(1),
    fill: 'currentColor',
    width: '1em',
    height: '1em',
    display: 'inline-block',
    fontSize: '1.2rem',
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    flexShrink: 0,
    "-moz-user-select": 'none',
    paddingLeft: 4, 
  }
}));

const theme = createMuiTheme({
  palette: {
    primary: { 500: '#2196F3' },
  },
});

export default function TextFields(props) {

	const classes = useStyles();
	const [valid, setValid] = React.useState(null);
	const [success, setSuccess] = React.useState(false);

	const [values, setValues] = React.useState({
    // clients, token
		'name': '',
		'email': '',
    'phone': '',
    'doc_number': '',

    // token
		'card[number]': '',
		'card[exp_year]': '',
		'card[exp_month]': '',
    'card[cvc]': '',
    
    // // clients, token
		// 'name': 'Softseguros test',
		// 'email': 'test@softseguros.com',
    // 'phone': '320000000',
    // 'doc_number': '1088746836',

    // // token
		// 'card[number]': '378282246310005',
		// 'card[exp_year]': '2017',
		// 'card[exp_month]': '07',
    // 'card[cvc]': '6353',
	});

  const token = React.useRef(props.match.params.token);

  React.useEffect(() => {

    axios.get(`${process.env.REACT_APP_API_URL}/publicsubscriptionlink/validate-subscription-link/${token.current}/`)
    .then(function (response) {
			setTimeout(() => {
      	setValid(true);
			}, 2000);
    })
    .catch(function (error) {
      setValid(false);
    });

  }, [])

	const handleChange = name => event => {
		setValues({ ...values, [name]: event.target.value });
	};

  function handleSubmit(event) {
    event.preventDefault();

    setValid(null);

    apiPost.saveDataToken(props.match.params.token, values)
    .then(function (response) {
			setTimeout(() => {
      	setSuccess(true);
			}, 2000);
    })
    .catch(function (error) {
      setValid(null);
      setSuccess(false);
    });
  }

	return (
    <Grid
    	className={classes.root}
    	container
			direction="row"
			justify="center"
			alignItems="center"
    >
      <Grid item xs={12} sm={8} md={7} lg={4}>
				<Paper className={classes.paper}>
          
          <Grid container className={classes.contentImages} spacing={0}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <img src={PagoAUnClickImg} alt="payment"/>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} style={{position: 'relative'}}>
              <img src={LogoSoftsegurosImg} alt="Logo - Softseguros" width="80%"/>
            </Grid>
          </Grid>

          {/* <div className={classes.contentImages}>
              <strong style={{fontSize: '1.25rem', width: '100%'}}><i>Pago a<br/> un click</i></strong>
              <img src={PaymentImg} className={classes.img} alt="payment"/>
					    <img src={LogoSoftsegurosImg} className={classes.img} alt="Logo - Softseguros"/>
          </div> */}

          { ( success &&
			      <Typography variant="subtitle1" align="center" className={classes.typography} paragraph>
			        Datos guardados.
              <p><small>ya puedes cerrar esta pestaña.</small></p>
          </Typography> )
            
            || ( valid === null && 	<Lottie/>)
            || ( valid === false &&
              <Typography variant="subtitle1" align="center" className={classes.typography} style={{margin: 16}}>
                El link ya no es valido.
              </Typography>
            )
            || ( valid === true &&
              <form autoComplete="off" onSubmit={handleSubmit}>
                <ThemeProvider theme={theme}>
                  <Typography variant="body1" className={classes.typography} paragraph>
                    Al diligenciar y enviar tus datos autorizas a <strong>ePayco</strong> a generar el cargo a tu entidad bancaria según la negociación que hiciste con Softseguros.
                    Así podemos garantizar un proceso confiable y seguro.
                    {/* Esta funcionalidad permite guardar de manera segura los datos de tú tarjeta de crédito. */}
                  </Typography>

                  <TextField
                    // label="Nombre del usuario de la tarjeta"
                    placeholder="Nombre del usuario de la tarjeta"
                    className={classes.textField}
                    value={values['name']}
                    onChange={handleChange('name')}
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    required={true}
                    autoFocus
                    InputProps={{ startAdornment: <PersonIcon className={classes.iconInput}/> }}
                  />
                  <TextField
                    // label="Número de documento"
                    placeholder="Número de documento"
                    className={classes.textField}
                    value={values['doc_number']}
                    onChange={handleChange('doc_number')}
                    margin="dense"
                    variant="outlined"
                    fullWidth required={true}
                    InputProps={{ startAdornment: <CrossIcon className={classes.customSvgIcon}/> }}
                  />
                  <TextField
                    // label="Email"
                    placeholder="Email"
                    type="email"
                    className={classes.textField}
                    value={values['email']}
                    onChange={handleChange('email')}
                    margin="dense"
                    variant="outlined"
                    fullWidth required={true}
                    InputProps={{ startAdornment: <EmailIcon className={classes.iconInput}/> }}
                  />
                  
                  <TextField
                    // label="Teléfono"
                    placeholder="Teléfono"
                    className={classes.textField}
                    value={values['phone']}
                    onChange={handleChange('phone')}
                    margin="dense"
                    variant="outlined"
                    fullWidth required={true}
                    InputProps={{ startAdornment: <PhoneIcon className={classes.iconInput}/> }}
                  />

                  <Grid container spacing={2}>

                    <Grid item xs={12} sm={12} className={classes.marginGrid}>
                      <TextField
                        // label="Número de tarjeta de crédito"
                        placeholder="Número de tarjeta de crédito"
                        className={classes.textField}
                        value={values['card[number]']}
                        onChange={handleChange('card[number]')}
                        margin="dense"
                        variant="outlined"
                        fullWidth required={true}
                        InputProps={{ startAdornment: <CreditCardIcon className={classes.customSvgIcon}/> }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4} className={classes.marginGrid}>
                      <TextField
                        // label="CVC"
                        placeholder="CVC"
                        className={classes.textField}
                        value={values['card[cvc]']}
                        onChange={handleChange('card[cvc]')}
                        margin="dense"
                        variant="outlined"
                        fullWidth required={true}
                        inputProps={{minLength:3, maxLength:4}}

                        // InputProps={{ startAdornment: <DialogWhatsCvc className={classes.iconInput}/> }}
                        InputProps={{ endAdornment: <DialogWhatsCvc/> }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4} className={classes.marginGrid}>
                      <TextField
                        // label="Mes de expiración"
                        placeholder="MM"
                        className={classes.textField}
                        value={values['card[exp_month]']}
                        onChange={handleChange('card[exp_month]')}
                        margin="dense"
                        variant="outlined"
                        fullWidth required={true}
                        inputProps={{minLength:2, maxLength:2, size:2}}
                        InputProps={{ startAdornment: <CalendarIcon className={classes.customSvgIcon}/> }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4} className={classes.marginGrid}>
                      <TextField
                        // label="Año de expiración"
                        placeholder="AAAA"
                        className={classes.textField}
                        value={values['card[exp_year]']}
                        onChange={handleChange('card[exp_year]')}
                        margin="dense"
                        variant="outlined"
                        fullWidth required={true}
                        inputProps={{minLength:4, maxLength:4, size:4}}
                        InputProps={{ startAdornment: <CalendarIcon className={classes.customSvgIcon}/> }}
                      />
                    </Grid>

                  </Grid>

                  <Button size="large" type="submit" variant="contained" color="primary" className={classes.button}
                    fullWidth
                    startIcon={<VerifiedUserIcon/>}
                  > <label>Registrar mis datos en <i>Pago a un click</i></label>
                  </Button>

                  <div className={classes.contentFooter}>
                    <Typography variant="body1" className={classes.typography} style={{marginBottom: 8}}>
                      En <i>Pago a un click</i> Softseguros usamos:
                    </Typography>
                    <img src={EpaycoPagoSeguroImg} className={classes.footerImg} alt="payment"/>
                  </div>
                </ThemeProvider>
              </form>
            )
          }

				</Paper>
      </Grid>
    </Grid>
	);
}