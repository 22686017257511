import React from 'react';

class ConvertDate extends React.Component {

  state = {
    seconds: null,
    value: "",
    cont:0,
  }
  intervalID = 0;

  componentWillUnmount() {clearInterval(this.intervalID);}
  componentWillMount(){
    this.intervalID = setInterval(() => {
      this.setState({cont: this.state.cont + 60})
    }, 60000);
  }

  format_date(value){

  	value = value + this.state.cont;

		if(!value){ return "";};

		var days = Math.floor(value / (60 * 60 * 24));
		var hours = Math.floor((value % (60 * 60 * 24)) / (60 * 60));
		var minutes = Math.floor((value % (60 * 60)) / (60));
		var seconds = Math.floor((value % (60)) / 1000);

		var format_date = days > 0 ? days.toFixed(0) + " días " : "";
		if (hours > 0 && hours < 24) {format_date += hours.toFixed(0) + " horas ";}
		if (minutes > 0 && minutes < 60) {format_date += minutes.toFixed(0) + " minutos ";}
		if (seconds > 0 && seconds < 60) {format_date += seconds.toFixed(0) + " segundos";}

    return format_date ? "Hace " + format_date : "Justo ahora";
  }

  render() {return this.format_date(this.props.seconds);}
}

export { ConvertDate };

export const NewID = function(prefix='id') {
		return Math.round((Math.random() * 36 ** 12)).toString(36);
};

export const ReturnInitials = function(string) {
	if(string){
		const regexp1 = string.match(/\b\w/g).slice().join('');
		string = regexp1.charAt(0) + regexp1.charAt(1);
	}
	 return string || "NN";
};

export const EllipsisText = function(text, total=50) {
	 return ((text).length > total) ? (((text).substring(0,total-3)) + '...') : text;
};

export const copyToClipBoard = (str) => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
};

// Obtener el usuario Determinar si está logeado o no  
export const getLocalToken = () => {
  return localStorage.getItem("user_token");
};

export const formatDate = (date) => {

    if(!date){return null}

    if(typeof date=='string'){
      date = new Date(date);
    }

    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    if(isNaN(year)){return null}

    return [year, month, day].join('-');
};

export const currencyFormat = (num, decimal=0) => {
  return num.toFixed(decimal).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}