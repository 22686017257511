import axios from 'axios';
import { getLocalToken } from "../utils";


const Auth = () => {

  const validateToken = () => {
    return new Promise( (resolve, reject) => {
      axios.get(`${process.env.REACT_APP_API_URL}/user/validate-token/`, {
        headers: {
          'content-type': 'application/json',
          'Authorization': 'Token ' + getLocalToken()
        }
      }).then(function (r) {
        resolve(r.data);
      }).catch(function (e) {
        reject(e.r);
      });

    });
  };

  return {
    validateToken
  }
}; 
  
export default Auth();