import axios from 'axios';  
import { getLocalToken } from "../utils";

const postRepository = () => {

	const saveDataToken = (token, post) => {
		return new Promise( (resolve, reject) => {
			const instance = axios.create({
				// baseURL : URL_API+"/address",
        baseURL: `${process.env.REACT_APP_API_URL}/publicsubscriptionlink/save-data-token/${token}/`,
				// headers: {
				// 	'Content-Type': 'application/json',
				// 	'Authorization': 'Token ' + getLocalToken()
				// }
			});
	 
			instance.post("", post)
			.then(r => {
				resolve(r.data);
			}).catch(e => {
				reject(e.response);
			});
		});
    
	};

  const saveCustomerObservations = (post) => {
		return new Promise( (resolve, reject) => {
			const instance = axios.create({
        baseURL: `${process.env.REACT_APP_API_URL}/clientobservation/`,
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Token ' + getLocalToken()
				}
			});
	 
			instance.post("", post)
			.then(r => {
				resolve(r.data);
			}).catch(e => {
				reject(e.response);
			});
		});
  };
  
	return {
    saveDataToken, saveCustomerObservations
  };
  
};

export default postRepository();