const initialState = {
	customer_list: {
		all: false,
		ids:[],
	},
	renovation_list: {
		all: false,
		ids:[],
	}
}

export default function checkbox_list(state=initialState, action){
	switch(action.type) {

    	case "REMOVE_ALL":
			return {...state, [action.table_name]: {
				all: false,
				ids:[],
			}};

		case "ALL_CHECKED":
			return {...state, [action.table_name]: {
				all: !state[action.table_name].all,
				ids:[],
			}};

		case "ADD_ID":

			const { value } = action;
			let { ids } = state[action.table_name];
			
			// Si tiene el id lo elimina
			if( ids.includes(value) ){

				const index = ids.indexOf(value);
				ids.splice(index, 1);
				
			}else{
				ids = ids.concat(value);
			}

			return {...state, [action.table_name]: { ids }};

		default: return state // Retornar el estado actual por defecto.
	}
};