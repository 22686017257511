import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '../../components/client-history/table';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2),
  },
  button:{
    margin: theme.spacing(1),
  }
}));

export default function Content(props) {
  const classes = useStyles();
  const { customer_ids } = props.location;
  console.log('------> index');
  return (
    <Paper className={classes.root}>
      <Table customer_ids={customer_ids}/>
    </Paper>
  );
}