import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import AsyncSelect from '../global/selectApi';
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import Checkbox from '@material-ui/core/Checkbox';

import { getLocalToken } from "../../utils";

const useStyles = makeStyles(theme => ({
  textField:{
    marginRight: theme.spacing(1),
  },
  divider:{
    padding: theme.spacing(.1),
    marginTop: theme.spacing(4)
  },
  textDivider:{
    top: theme.spacing(-2) + 4,
    position: 'relative',
    background: 'white',
    padding: theme.spacing(1),
  }
}));

export default function FormDialog(props) {

  const classes = useStyles();
  // const [success, setSuccess] = React.useState(false);
  const [msgError, setMsgError] = React.useState();

  const newDate = new Date();
  newDate.setHours(newDate.getHours() + 48);

  const [data, setData] = React.useState({

    domain: window.location.origin,
    send_link: true,
    
    branch:{
      id: null,
      nombre: null,
      email: '',
      cell_phone: '',
      expiration_datetime: newDate,
    },

    softseguros_license: {
      id: null,
      usuarios: '',
      megas_almacenamiento: '',
      polizas_vigentes: '',
      sedes: '',
      correos: '',
      sms: '',
      llamadas: '',
    },

    epayco_plan:{
      id_plan: '',
      name: '',
      description: '',
      amount: '',
      currency: '',
      interval: '',
      interval_count: '',
      trialDays: '',
    }

  });

  function handleDateChange(value){
    setData({...data, branch: {...data.branch, expiration_datetime: value}});
  }

  function handleChangeBranch(value) {
    const marcalicencia = value.marcalicencia;
    marcalicencia.id = marcalicencia.licencia;

    setData({
      ...data,
      branch: {...data.branch, ...value},
      softseguros_license: {...data.softseguros_license, ...marcalicencia},
    });
  }

  function handleChangeLicense(value) {
    console.log("value", value);
    setData({...data, softseguros_license: value});
  }

  function handleChangeEpaycoPlan(value) {
    setData({...data, epayco_plan: value});
  }

  const handleChange = name => event => {
    setData({ ...data,
      softseguros_license:{
        ...data.softseguros_license,
        [name]: event.target.value
      }
    });
  };

  const handleChangeCheckbox = event => {
    setData({ ...data, send_link: event.target.checked });
  };

  function handleSubmit(event) {

    if(
      !data.branch.id ||
      !data.epayco_plan.id_plan ||
      !data.softseguros_license.id
    ){
      setMsgError("Completar los datos");
      return;
    }

    setMsgError("");

    axios.post(`${process.env.REACT_APP_API_URL}/subscriptionlink/`, data, {
      headers: {
        'content-type': 'application/json',
        'Authorization': 'Token ' + getLocalToken()
      }
    })
    .then(function (response) {
      props.handleReloadPage();
    })
    .catch(function (error) {
      setMsgError("Error guardando la información.");
    });

  }


  return (
    <Dialog open={props.open} onClose={props.handleClose} fullWidth={true} maxWidth="md"
      aria-labelledby="form-dialog-title"
      PaperProps={{style: {overflowY: 'visible'}}}
    >
      <DialogTitle id="form-dialog-title">
        Crear Suscripción
        <IconButton onClick={props.handleClose} style={{float: 'right', margin: -8}}><CloseIcon/></IconButton>
      </DialogTitle>
      <DialogContent style={{ overflowY: 'visible' }}>
        {/* <DialogContentText>
          To subscribe to this website, please enter your email address here. We will send updates
          occasionally.
        </DialogContentText> */}

        <Grid container spacing={1}>
          <Grid item xs={12} sm={4}>
            <AsyncSelect
              placeholder="* Buscar Marca"
              url={`${process.env.REACT_APP_API_URL}/marca/`}
              callback={handleChangeBranch}
              autoFocus
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField className={classes.textField} variant="outlined" margin="dense" label="* Correo"
              value={data.branch.email}
              // onChange={handleChange('email')}
              fullWidth inputProps={{readOnly: true, tabIndex: -1}}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField className={classes.textField} variant="outlined" margin="dense" label="* Celular (SMS)"
              value={data.branch.cell_phone}
              // onChange={handleChange('cell_phone')}
              fullWidth inputProps={{readOnly: true, tabIndex: -1}}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <DateTimePicker
                // format
                minutesStep={5}
                className={classes.textField}
                value={data.branch.expiration_datetime}
                // value={selectedDate}
                label="* Fecha de caducidad y hora"
                inputVariant="outlined"
                margin="dense"
                fullWidth
                onChange={handleDateChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={4} style={{paddingTop: "1%"}}>
            <label>
              <Checkbox
                checked={data.send_link}
                onChange={handleChangeCheckbox}
                value="checkedA"
                color="primary"
                inputProps={{
                  'aria-label': 'secondary checkbox',
                }}
              />Enviar link al cliente.
            </label>
          </Grid>
        </Grid>

        <Grid item sm={12} align="center">
          <Divider className={classes.divider}/>
          <Typography variant="body1" component="label" className={classes.textDivider}>Licencia Softseguros</Typography>
        </Grid>

        <Grid container spacing={1}>

          <Grid item xs={12} sm={4}>
            <AsyncSelect
              select_value={ data.softseguros_license.id ? {
                value: data.softseguros_license.id,
                label: data.softseguros_license.licencia_nombre || data.softseguros_license.nombre,
                // label: data.softseguros_license.licencia_nombre,
              } : null }
              placeholder={ "* Licencia Softseguros" }
              url={`${process.env.REACT_APP_API_URL}/licencia/`}
              callback={handleChangeLicense}
              defaultOptions={true}
              isSearchable={false}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <TextField className={classes.textField} variant="outlined" margin="dense" label="* Usuarios"
              value={data.softseguros_license.usuarios}
              onChange={handleChange('usuarios')}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <TextField className={classes.textField} variant="outlined" margin="dense" label="* Gb"
              value={data.softseguros_license.megas_almacenamiento}
              onChange={handleChange('megas_almacenamiento')}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <TextField className={classes.textField} variant="outlined" margin="dense" label="* Pólizas"
              value={data.softseguros_license.polizas_vigentes}
              onChange={handleChange('polizas_vigentes')}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <TextField className={classes.textField} variant="outlined" margin="dense" label="* Sedes"
              value={data.softseguros_license.sedes}
              onChange={handleChange('sedes')}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <TextField className={classes.textField} variant="outlined" margin="dense" label="* Correos"
              value={data.softseguros_license.correos}
              onChange={handleChange('correos')}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <TextField className={classes.textField} variant="outlined" margin="dense" label="* Sms"
              value={data.softseguros_license.sms}
              onChange={handleChange('sms')}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <TextField className={classes.textField} variant="outlined" margin="dense" label="* Llamadas"
              value={data.softseguros_license.llamadas}
              onChange={handleChange('llamadas')}
              fullWidth
            />
          </Grid>
 
        </Grid>

        <Grid item sm={12} align="center">
          <Divider className={classes.divider}/>
          <Typography variant="body1" component="label" className={classes.textDivider}>Plan Epayco</Typography>
        </Grid>

        <Grid container spacing={1}>

          <Grid item xs={12} sm={4}>
            <AsyncSelect
              placeholder="* Plan Epayco"
              url={`${process.env.REACT_APP_API_URL}/subscriptionlink/epayco-plans/`}
              callback={handleChangeEpaycoPlan}
              defaultOptions={true}
              isSearchable={false}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <TextField value={data.epayco_plan.interval}
              className={classes.textField}
              variant="outlined"
              margin="dense"
              label="* Recurrencia"
              fullWidth inputProps={{readOnly: true}}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <TextField value={data.epayco_plan.interval_count}
              className={classes.textField}
              variant="outlined"
              margin="dense"
              label="* Frecuencia"
              fullWidth inputProps={{readOnly: true}}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <TextField value={data.epayco_plan.amount}
              className={classes.textField}
              variant="outlined"
              margin="dense"
              label="* Valor"
              fullWidth inputProps={{readOnly: true}}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <TextField value={data.epayco_plan.currency}
              className={classes.textField}
              variant="outlined"
              margin="dense"
              label="* Moneda"
              fullWidth inputProps={{readOnly: true}}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <TextField value={data.epayco_plan.trialDays}
              className={classes.textField}
              variant="outlined"
              margin="dense"
              label="* Días de prueba"
              fullWidth inputProps={{readOnly: true}}
            />
          </Grid>

          <Grid item xs={12} sm={10}>
            <TextField value={data.epayco_plan.description}
              className={classes.textField}
              variant="outlined"
              margin="dense"
              label="* Descripción"
              fullWidth inputProps={{readOnly: true}}
            />
          </Grid>

        </Grid>


      </DialogContent>
      { msgError && <Typography color="error" align="center" gutterBottom>{msgError}</Typography>}
      <DialogActions>
        {/* <Button onClick={props.handleClose} color="primary">
          Cancel
        </Button> */}
        <Button onClick={handleSubmit} variant="contained" color="primary" fullWidth>
          Generar y enviar link de suscripción
        </Button>
      </DialogActions>
    </Dialog>
  );
}
