import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TableCell from '@material-ui/core/TableCell';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {EllipsisText, copyToClipBoard} from '../../utils';

const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2),
  },
  jsonContent: {
    background: 'white',
    padding: theme.spacing(1),
    position: 'absolute',
    top: 36,
    right: 0,
    left: 0,
    zIndex: 1,
    width: 'min-content',
  }
}));

export default function SimplePopover(props) {

  const { row } = props;

  const classes = useStyles();

  const [openSoftsegurosLicense, setOpenSoftsegurosLicense] = React.useState(false);
  const handleClickSoftsegurosLicense = () => { setOpenSoftsegurosLicense(prev => !prev); };
  const handleClickAwaySoftsegurosLicense = () => { setOpenSoftsegurosLicense(false); };

  const [openEpaycoPlan, setEpaycoPlan] = React.useState(false);
  const handleClickEpaycoPlan = () => { setEpaycoPlan(prev => !prev); };
  const handleClickAwayEpaycoPlan = () => { setEpaycoPlan(false); };

  const [anchorEl, setAnchorEl] = React.useState(null);
  function handleClickMenu(event) { setAnchorEl(event.currentTarget); }
  function handleCloseMenu() { setAnchorEl(null); }

  return (
    <React.Fragment>
      <TableCell component="th" scope="row">{row.marca_nombre}</TableCell>

      <TableCell>
        <div style={{position: 'relative'}}>
          <ClickAwayListener onClickAway={handleClickAwaySoftsegurosLicense}>
            <div>
              <Button variant="outlined" onClick={handleClickSoftsegurosLicense} style={{ textTransform: 'initial' }}>{row.data.softseguros_license.nombre}</Button>
              {openSoftsegurosLicense ? (
                <Paper className={classes.jsonContent}>
                  <pre><code>{JSON.stringify(row.data.softseguros_license, null, 2)}</code></pre>
                </Paper>
              ) : null}
            </div>
          </ClickAwayListener>
        </div>
      </TableCell>

      <TableCell>
        <div style={{position: 'relative'}}>
          <ClickAwayListener onClickAway={handleClickAwayEpaycoPlan}>
            <div>
              <Button variant="outlined" onClick={handleClickEpaycoPlan} style={{ textTransform: 'initial' }}>{row.data.epayco_plan.name}</Button>
              {openEpaycoPlan ? (
                <Paper className={classes.jsonContent}>
                  <pre><code>{JSON.stringify(row.data.epayco_plan, null, 2)}</code></pre>
                </Paper>
              ) : null}
            </div>
          </ClickAwayListener>
        </div>
      </TableCell>

      <TableCell>
        <Typography color={row.link_expired ? "error" : "initial" } align="center" gutterBottom>
          { new Date(row.expiration_datetime).toLocaleString() }
        </Typography>
      </TableCell>
      <TableCell>
        {row.used
          ? <Chip variant="outlined" color="primary" size="small" label="Si"/>
          : <Chip variant="outlined" color="secondary" size="small" label="No"/>
        }
      </TableCell>
      <TableCell
        style={{cursor: 'pointer'}}
        onClick={()=>{copyToClipBoard(window.location.origin+'/generate-token/'+row.verify_code)}}
      >
        {EllipsisText(window.location.origin+'/generate-token/'+row.verify_code)}
      </TableCell>
      <TableCell>

        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClickMenu}
        >
          <MoreVertIcon />
        </IconButton>

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <MenuItem onClick={handleCloseMenu}></MenuItem>
          <MenuItem onClick={handleCloseMenu}></MenuItem>
          <MenuItem onClick={handleCloseMenu}></MenuItem>
        </Menu>
      </TableCell>
    </React.Fragment>
  );
}
