import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ApiGet from '../../api/Get';

import CardAreaChart from '../../components/start-panel/CardAreaChart';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(2), 
  },
}));

export default function SimpleCard() {
  const classes = useStyles();

  const [dataWidgets, setDataWidgets] = React.useState({});
  function getList(){
    ApiGet.listDataWidgets()
    .then(r => {


      console.log("r", r);

      setDataWidgets(r);
    }).catch(e => {
      console.log("e", e);
    });
  }

  React.useEffect(() => {
    getList();

    // setTimeout(() => {
    //   setDataWidgets({...dataWidgets, REMINDER_OF_REMARKS: 8, REMINDER_OF_REMARKStest:2})
    // }, 3000);

  }, []);

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        spacing={2}
        // zeroMinWidth={true}
      >

        <Grid item xs={12} sm={4} md={3} lg={2}>
          <CardAreaChart
            title="RECORDATORIO DE OBSERVACIONES"
            subtitle="próximos 2 días"
            value={dataWidgets.REMINDER_OF_REMARKS}
            ids={dataWidgets.IDS_CUSTOMERS_WITH_REMINDER_REMINDER}
          />
        </Grid>

        {/* <Grid item xs={12} sm={4} md={3} lg={4}>
          </>
        </Grid> */}
        
        {/* <Link
          to={{ pathname: '/customers', customer_ids: [1, 2, 4] }}
        >Create Idea</Link> */}

      </Grid>
    </div>
  );
}
