import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import {formatDate} from '../../../utils';

const useStyles = makeStyles((theme) => ({
  // textfield: {
  //   '& > *': {
  //     margin: theme.spacing(1),
  //     marginLeft: 0,
  //   },
  // },
}));

const _date = new Date();
_date.setDate(_date.getDate() + 20);

const stateDefault = {
  incremento_renovacion: 7,
  notificar_renovacion: false,
  from_email: "renovaciones@softseguros.com",
  expiration_datetime: formatDate(_date),
  other_emails: null,
  adicionar_paquete:false,
};

export default function DialogApplyRenewal(props) {

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState(stateDefault);

  const { label, handleGetList } = props;

  const handleClickOpen = () => {

    if(props.onClose){
      props.onClose();
    }
    
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDateChange = (name, date)=>{
    setData({...data, [name]: formatDate(date) });
  }

  return (
    <div>
      <span onClick={handleClickOpen}>Aplicar incremento de renovación</span>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{style: {overflowY: 'visible'}}}
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">Aplicar incremento de renovación</DialogTitle>
        <DialogContent style={{ overflowY: 'visible', textAlign: 'center' }}>

          <Grid container spacing={3} direction="column" alignContent="center">

            <Grid item xs={10}>
              {/* <label>% Incremento renovación</label> */}
              <TextField type="number" variant="outlined"
                label="% Incremento"
                className={classes.textfield}
                autoFocus={open}
                defaultValue={data.incremento_renovacion}
                onChange={(event) => { setData({...data, incremento_renovacion: event.target.value}); }}
                fullWidth
              />
            </Grid>
            <Grid item xs={10}>
              {/* <label>Fecha limite de pago</label> */}
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <KeyboardDatePicker
                  autoOk
                  label="Fecha limite de pago"
                  format="yyyy-MM-dd"
                  value={data.expiration_datetime ? new Date(data.expiration_datetime+'T00:00:00') : null}
                  placeholder="Fecha inicio"
                  inputVariant="outlined"
                  fullWidth
                  // onChange={(date) => { setData({...data, expiration_datetime: date ? date.toLocaleDateString() : date }); }}
                  onChange={date => handleDateChange("expiration_datetime", date)}
                  
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={10}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                      name="checkedA"
                    checked={data.adicionar_paquete}
                    onChange={(event) => { setData({...data, adicionar_paquete: event.target.checked}); }}
                  />}
                label="Adicionar paquetes   "
              />
            </Grid>
            <Grid item xs={10}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                      name="checkedA"
                    checked={data.notificar_renovacion}
                    onChange={(event) => { setData({...data, notificar_renovacion: event.target.checked}); }}
                  />}
                label="Notificar renovación"
              />
            </Grid>
            <Grid item xs={10}>
              <div style={{display: data.notificar_renovacion ? "block" : "none"}}>
                <TextField type="text" variant="outlined"
                  fullWidth
                  label="Se envia la renovación con"
                  className={classes.textfield}
                  defaultValue={data.from_email}
                  onChange={(event) => { setData({...data, from_email: event.target.value}); }}
                />
              </div>
            </Grid>
            <Grid item xs={10}>
              <div style={{display: data.notificar_renovacion ? "block" : "none"}}>
                <TextField type="text" variant="outlined"
                  fullWidth
                  label="Otros correos a donde se envía"
                  className={classes.textfield}
                  defaultValue={data.other_emails}
                  placeholder="Separados por ;"
                  InputLabelProps={{shrink: true}}
                  onChange={(event) => {  setData({...data, other_emails: event.target.value}); }}
                />
              </div>
            </Grid>
          </Grid>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            cerrar
          </Button>
          <Button onClick={()=>{
            setData(stateDefault);
            handleClose();
            handleGetList(data);
          }} color="primary" autoFocus>
            guardar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}