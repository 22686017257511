import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import {EllipsisText, formatDate} from '../../utils';
import AsyncSelect from '../global/selectApi';
import ApiPut from '../../api/Put';
import DialogObservations from '../global/menu/DialogObservations';
import CheckboxRedux from '../global/checkbox_list';
import SiOrNO from './checkbox_siorno';

const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2),
  },
  jsonContent: {
    background: 'white',
    padding: theme.spacing(1),
    position: 'absolute',
    top: 36,
    right: 0,
    left: 0,
    zIndex: 1,
    width: 'min-content',
  },
  rowSelected:{
    background: '#c5cae9',
    // background: '#bbdefb',
    '&:hover': {
      background: '#9fa8da !important',
      // background: '#90caf9 !important',
    }
  },
  customWidth:{
    width: '180px !important',
    minWidth: '180px !important',
    maxWidth: '180px !important',
  },
  customWidthDate:{
    width: '140px !important',
    minWidth: '140px !important',
    maxWidth: '140px !important',
  }
}));

export default function SimplePopover(props) {

  const [row, setRow] = React.useState(props.row);

  const classes = useStyles();

  const handleChangeClientType = ({value}) => {
    ApiPut.updateMarca(row.id, {
      client_type: value,
    })
    .then(function(response) {})
    .catch(function(error) { console.log("error", error); });
  }

  const handleChangeState = value => {

    ApiPut.updateMarca(row.id, {
      state_id: value.id,
    })
    .then(function(response) {
    })
    .catch(function(error) {
      console.log("error", error);
    });

  }

  const [openDialogObservations, setOpenDialogObservations] = React.useState(false);

  function handleClickOpenDialogObservations() {
    setOpenDialogObservations(true);
  }

  function handleCloseDialogObservations() {
    setOpenDialogObservations(false);
  }

  const handleChangeCheck = (name, checked)=>{
    ApiPut.updateMarca(row.id, {
      [name]: checked,
    })
    .then(function(response) {
      console.log('response', response)
      //setRow({...row, marked: checked})
    })
    .catch(function(error) {
      console.log("error", error);
    });

  }
  
  return (
    // <TableRow hover className={ row.marked && classes.rowSelected }>
    <TableRow hover className={row.marked ? classes.rowSelected : null}>

      <TableCell>
          <CheckboxRedux id={row.id} list_name="customer_list"/>
      </TableCell>
      
      <TableCell>{row.id}</TableCell>
      <TableCell>{row.nombre}</TableCell>

      <TableCell className={classes.customWidth}>
          <AsyncSelect
            placeholder="Tipo"
            defaultValue={ row.tipo_cliente ? {
              value: row.tipo_cliente,
              label: row.tipo_cliente.toLowerCase().replace("_", " "),
            } : null }
            callback={handleChangeClientType}
            defaultOptions={true}
            isSearchable={false}
            isPromiseOptions={false}
          />
      </TableCell>
      
      <TableCell className={classes.customWidth}>
          <AsyncSelect
            placeholder="Estado"
            defaultValue={ row.state ? {
              value: row.state,
              label: row.state_name,
            } : null }
            url={`${process.env.REACT_APP_API_URL}/clientstate/`}
            callback={handleChangeState}
            defaultOptions={true}
            isSearchable={false}
          />
      </TableCell>

      <TableCell className={classes.customWidth}>
          <SiOrNO state={row.cotizador_externo} callback={handleChangeCheck} name={'cotizador_externo'}/>
      </TableCell>
      <TableCell className={classes.customWidth}>
          <SiOrNO state={row.has_electronic_invoice} name={'has_electronic_invoice'} disable={true}/>
      </TableCell>
      <TableCell className={classes.customWidth}>
          <SiOrNO state={row.has_pagina_web} callback={handleChangeCheck} name={'has_pagina_web'}/>
      </TableCell>
      <TableCell className={classes.customWidth}>
          <SiOrNO state={row.has_marca_blanca} callback={handleChangeCheck} name={'has_marca_blanca'}/>
      </TableCell>
      <TableCell className={classes.customWidth}>
          <SiOrNO state={row.has_quote_requests} callback={handleChangeCheck} name={'has_quote_requests'}/>
      </TableCell>
      <TableCell className={classes.customWidth}>
          <SiOrNO state={row.activo} callback={handleChangeCheck} name={'activo'}/>
      </TableCell>

      <TableCell>

        <Chip
          clickable={true}
          label={EllipsisText(row.last_observation ? row.last_observation : "Agregar una", 20)}
          variant="outlined"
          size="small"
          onClick={handleClickOpenDialogObservations}
        />
        { openDialogObservations && <DialogObservations
          open={openDialogObservations}
          handleClose={handleCloseDialogObservations}
          marca_id={row.id}
          marca_name={row.nombre}
          callback={(value)=>{setRow({...row, last_observation: value})}}
        />}

      </TableCell>

    </TableRow>
  );
}
