const initialState = [];

export default function stage_list(state=initialState, action){
	switch(action.type) {
		case "ADD":
			return [
        ...state,
        state.list
      ]

		default: return state // Retornar el estado actual por defecto.
	}
};