import React from 'react';
import './App.css';

import { BrowserRouter as Router, Route } from 'react-router-dom';
import { AnimatedSwitch } from 'react-router-transition';

import LoginAdmin from './pages/login-admin/index.jsx';
import GenerateToken from './components/subscription-link/generate-token';
import Drawer from './pages/drawer';
import DetailPaymentLink from './pages/detail-payment-link';

function App() {
  return (
    <Router>
      <AnimatedSwitch
        atEnter={{ opacity: 0 }}
        atLeave={{ opacity: 1 }}
        atActive={{ opacity: 1 }}
        className='switch-wrapper'
      >
        <Route exact path='/login' component={LoginAdmin} />
        <Route exact path='/generate-token/:token' component={GenerateToken}/>
        <Route exact path='/detail-payment-link/:data' component={DetailPaymentLink}/>
        <Route path='/' component={Drawer} />

        {/* <Redirect from="/" to="/login" /> */}
        {/*<Route path="*" component={NotFound} status={404} />*/}
      </AnimatedSwitch>
    </Router>
  );
}

export default App;
