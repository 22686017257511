import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import { getLocalToken } from "../../utils";
import { Redirect } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    minHeight: '100vh',
    padding: theme.spacing(1),
  },
  paper: {
    margin: 'auto',
    width: 500,
    maxWidth: 500,
    borderRadius: 10,
    overflow: 'hidden',
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  header:{
    width: '100%',
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: '70px 15px 74px',
    backgroundImage: 'url(https://colorlib.com/etc/lf/Login_v15/images/bg-01.jpg)',
    height: 128,
    '&::before': {
      content: "''",
      display: 'block',
      position: 'absolute',
      zIndex: '-1',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      backgroundColor: 'rgba(54,84,99,.7)',
    }
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    width: 400,
    margin: '0 auto',
  },
  button: {
    marginTop: theme.spacing(2),
    margin: theme.spacing(1),
  },
}));

export default function ComplexGrid() {
  const classes = useStyles();

  const [msgError, setMsgError] = React.useState();

  const [values, setValues] = React.useState({
    username: '',
    password: '',
  });

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setMsgError("");
    axios.post(`${process.env.REACT_APP_DOMAIN_URL}/api-token-auth/`, values)
    .then(function (response) {
      localStorage.setItem("user_token", response.data.token);
      
      window.location.replace('/');
    })
    .catch(function (error) {
      if(!error.response){
        setMsgError("No es posible conectarse con el servidor.");
      } else if(error.response.status===400){
        setMsgError("Nombre de usuario o contraseña incorrecto.");
      }
    });

  };

  if(getLocalToken()){
    return <Redirect to="/"/>
  }

  return (
    <Grid container className={classes.root} direction="row" justify="center" alignItems="center" spacing={0}>
      <Paper className={classes.paper}>
        <Grid item xs={12} className={classes.header}>
          <Typography variant="h4" gutterBottom style={{color:"#fff", textTransform: 'uppercase'}}>Ingresar</Typography>
        </Grid>
        <Grid item xs={12} sm container className={classes.form}>
          <form onSubmit={handleSubmit}>
            
            { msgError && <Typography color="error" align="center" gutterBottom>{msgError}</Typography>}
            
            <TextField
              id="standard-name"
              label="Usuario"
              className={classes.textField}
              value={values.username}
              onChange={handleChange('username')}
              margin="normal" required
              variant="outlined"
              fullWidth autoFocus
            />
            <TextField
              id="standard-name"
              label="Contraseña"
              className={classes.textField}
              value={values.password}
              onChange={handleChange('password')}
              margin="normal" required
              variant="outlined"
              fullWidth type="password"
            />
            <Button type="submit" size="large" variant="contained" color="primary"
              className={classes.button} fullWidth>Ingresar</Button>
          </form>
        </Grid>
      </Paper>
    </Grid>
  );
}
