import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import TextField from '@material-ui/core/TextField';
import {EllipsisText, formatDate} from '../../utils';
import AsyncSelect from '../global/selectApi';
import ApiPut from '../../api/Put';
import DialogObservations from '../global/menu/DialogObservations';
import CheckboxRedux from '../global/checkbox_list';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2),
  },
  jsonContent: {
    background: 'white',
    padding: theme.spacing(1),
    position: 'absolute',
    top: 36,
    right: 0,
    left: 0,
    zIndex: 1,
    width: 'min-content',
  },
  rowSelected:{
    background: '#c5cae9',
    // background: '#bbdefb',
    '&:hover': {
      background: '#9fa8da !important',
      // background: '#90caf9 !important',
    }
  },
  customWidth:{
    width: '180px !important',
    minWidth: '180px !important',
    maxWidth: '180px !important',
  },
  customWidthDate:{
    width: '140px !important',
    minWidth: '140px !important',
    maxWidth: '140px !important',
  }
}));

export default function SimplePopover(props) {

  const [row, setRow] = React.useState(props.row);

  const classes = useStyles();

  const handleChangeClientType = ({value}) => {
    ApiPut.updateMarca(row.id, {
      client_type: value,
    })
    .then(function(response) {})
    .catch(function(error) { console.log("error", error); });
  }

  const handleChangeState = value => {

    ApiPut.updateMarca(row.id, {
      state_id: value.id,
    })
    .then(function(response) {
    })
    .catch(function(error) {
      console.log("error", error);
    });

  }

  const handleChangeStage = value => {

    ApiPut.updateMarca(row.id, {
      stage_id: value.id,
    })
    .then(function(response) {
    })
    .catch(function(error) {
      console.log("error", error);
    });

  }

  const handleSaveMarkCustomer = (event) => {

    const checked = event.target.checked;

    ApiPut.updateMarca(row.id, {
      marked: checked,
    })
    .then(function(response) {
      setRow({...row, marked: checked})
    })
    .catch(function(error) {
      console.log("error", error);
    });

  }

  const [openDialogObservations, setOpenDialogObservations] = React.useState(false);

  function handleClickOpenDialogObservations() {
    setOpenDialogObservations(true);
  }

  function handleCloseDialogObservations() {
    setOpenDialogObservations(false);
  }

  const handleDateChange = (name, date)=>{

    let value = formatDate(date);

    if(!value){ return; }

    ApiPut.updateMarca(row.id, {
      entry_date: value,
    })
    .then(function(response) {
      setRow({...row, entry_date: value})
    })
    .catch(function(error) {
      console.log("error", error);
    });

  }
  
  return (
    // <TableRow hover className={ row.marked && classes.rowSelected }>
    <TableRow hover className={row.marked ? classes.rowSelected : null}>

      <TableCell>
          <CheckboxRedux id={row.id} list_name="customer_list"/>
      </TableCell>
      
      <TableCell>{row.id}</TableCell>
      <TableCell>{row.nombre}</TableCell>

      <TableCell className={classes.customWidth}>
          <AsyncSelect
            placeholder="Tipo"
            defaultValue={ row.tipo_cliente ? {
              value: row.tipo_cliente,
              label: row.tipo_cliente.toLowerCase().replace("_", " "),
            } : null }
            callback={handleChangeClientType}
            defaultOptions={true}
            isSearchable={false}
            isPromiseOptions={false}
          />
      </TableCell>
      
      <TableCell className={classes.customWidth}>
          <AsyncSelect
            placeholder="Estado"
            defaultValue={ row.state ? {
              value: row.state,
              label: row.state_name,
            } : null }
            url={`${process.env.REACT_APP_API_URL}/clientstate/`}
            callback={handleChangeState}
            defaultOptions={true}
            isSearchable={false}
          />
      </TableCell>

      <TableCell className={classes.customWidth}>
          <AsyncSelect
            placeholder="Etapa"
            defaultValue={ row.stage ? {
              value: row.stage,
              label: row.stage_name,
            } : null }
            url={`${process.env.REACT_APP_API_URL}/clientstage/`}
            callback={handleChangeStage}
            defaultOptions={true}
            isSearchable={false}
          />
      </TableCell>

      <TableCell>{row.number_policies_generated}</TableCell>
      <TableCell>{row.ciudad}<br/>{row.pais}</TableCell>
      {/* <TableCell>{row.pais}</TableCell> */}
      <TableCell>{row.licencia_nombre}</TableCell>
      <TableCell>{row.current_policies}</TableCell>
      <TableCell>{row.nombre_contacto}</TableCell>
      <TableCell>{row.celular}<br/>{row.email} </TableCell>
      <TableCell>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            className={classes.customWidthDate}
            autoOk
            format="yyyy-MM-dd"
            value={row.entry_date ? new Date(row.entry_date+'T00:00:00') : null}
            placeholder=""
            fullWidth
            margin="dense"
            onChange={date => handleDateChange("entry_date", date)}
            // TextFieldComponent={TextFieldComponent}
          />
        </MuiPickersUtilsProvider>

      </TableCell>
      {/* <TableCell>{EllipsisText(row.email, 10)}</TableCell> */}
      {/* <TableCell
        onClick={()=>{copyToClipBoard(row.email)}}
      >{row.email}
      </TableCell> */}
      <TableCell>

        <Chip
          clickable={true}
          label={EllipsisText(row.last_observation ? row.last_observation : "Agregar una", 20)}
          variant="outlined"
          size="small"
          onClick={handleClickOpenDialogObservations}
        />
        { openDialogObservations && <DialogObservations
          open={openDialogObservations}
          handleClose={handleCloseDialogObservations}
          marca_id={row.id}
          marca_name={row.nombre}
          callback={(value)=>{setRow({...row, last_observation: value})}}
        />}

      </TableCell>
      {/* <TableCell>{row.asd}</TableCell> */}
      <TableCell>{row.activo ? "Si" : "No"}</TableCell>
      <TableCell>
          <Checkbox
            checked={row.marked}
            onChange={handleSaveMarkCustomer}
            value="checkedA"
            color="primary"
            inputProps={{
              'aria-label': 'secondary checkbox',
            }}
          />
      </TableCell>
      <TableCell style={{minWidth: 170}}>
        <Grid container spacing={3} >
          <Grid item xs={6}>
            <Chip size="small" style={{marginBottom: 8}} variant="outlined" label="Empresa" color={row.id_empresa_hubspot ? "primary" : "secondary"}/>
          </Grid>
          <Grid item xs={6}>
            <Chip size="small" variant="outlined" label="Negocio" color={row.id_negocio_hubspot ? "primary" : "secondary"}/>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
}
