import axios from 'axios';
import { getLocalToken } from "../utils";

const getRepository = () => {

  const listSubscriptionLink = (page) => {
    return new Promise( (resolve, reject) => {

      axios.get(`${process.env.REACT_APP_API_URL}/subscriptionlink/`, {
        headers: {
          'content-type': 'application/json',
          'Authorization': 'Token ' + getLocalToken()
        },
        params: {page}
      }).then(function (r) {
        resolve(r.data);
      }).catch(function (e) {
        reject(e.r);
      });

    });
  };

  const listCustomers = (data) => {
    return new Promise( (resolve, reject) => {
      let headers = {
        'Authorization': 'Token ' + getLocalToken()
      };

      axios.get(`${process.env.REACT_APP_API_URL}/marca/`, {
        headers,
        params: data
      }).then(function (r) {
        resolve(r.data);
      }).catch(function (e) {
        reject(e.r);
      });

    });
  };

  const listRenovations = (data) => {
    return new Promise( (resolve, reject) => {
      let headers = {
        'Authorization': 'Token ' + getLocalToken()
      };

      axios.get(`${process.env.REACT_APP_API_URL}/marca/list-renovations/`, {
        headers,
        params: data
      }).then(function (r) {
        resolve(r.data);
      }).catch(function (e) {
        reject(e.r);
      });

    });
  };

  const listCustomerObservations  = (data) => {
    return new Promise( (resolve, reject) => {
      let headers = {
        'Authorization': 'Token ' + getLocalToken()
      };

      axios.get(`${process.env.REACT_APP_API_URL}/clientobservation/`, {
        headers,
        params: data
      }).then(function (r) {
        resolve(r.data);
      }).catch(function (e) {
        reject(e.r);
      });

    });
  };

  const listDataWidgets  = () => {
    return new Promise( (resolve, reject) => {
      let headers = {
        'Authorization': 'Token ' + getLocalToken()
      };

      axios.get(`${process.env.REACT_APP_API_URL}/user/data-widgets/`, {
        headers,
      }).then(function (r) {
        resolve(r.data);
      }).catch(function (e) {
        reject(e.r);
      });

    });
  };

  return {
    listSubscriptionLink, listCustomers, listCustomerObservations, listDataWidgets, listRenovations
  }
}; 
  
export default getRepository();