import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import { debounce } from 'lodash';
import ApiPut from '../../api/Put';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'inline-block',
    '& > *': {
      margin: 2,
      width: '12ch',
    },
  },
  linearprogress: {
    position: 'absolute',
    bottom: 0,
  }
}));

export default function TextfieldApi(props) {
  const classes = useStyles();

  const [ value, setValue ] = useState(props.value);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const Template = React.forwardRef((propsRef, ref) => {
    return <div {...propsRef} ref={ref} className={classes.root}>
      <TextField
        variant="outlined"
        size="small"
        defaultValue={value}
        onChange={(e) => handleChange(e.currentTarget.value)}
        error={error}
        InputProps={ props.icon && { endAdornment: props.icon } }
        label={props.label && props.label}
        />
      {loading && <LinearProgress size={24} className={classes.linearprogress} />}
    </div>
  });

  const handleChange = debounce(function (targetValue) {
    setLoading(true);
    setError(false);
    setValue(targetValue);

    ApiPut.updateMarca(props.id, {
      [props.nameField]: targetValue,
    })
    .then(function(response) {
      setLoading(false);
    })
    .catch(function(error) {
      setLoading(false);
      setError(true);
    });

  }, 700)

  return ( props.titleTooltip && <Tooltip title={props.titleTooltip}><Template/></Tooltip> ||  <Template/>);
}
