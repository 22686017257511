import axios from 'axios';  
import { getLocalToken } from "../utils";

const postRepository = () => {

	const updateMarca = (pk, put) => {
		return new Promise( (resolve, reject) => {
			const instance = axios.create({
        baseURL: `${process.env.REACT_APP_API_URL}/marca/${pk}/`,
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Token ' + getLocalToken()
				}
			});
	 
			instance.put("", put)
			.then(r => {
				resolve(r.data);
			}).catch(e => {
				reject(e.response);
			});
		});
	};

	return {
    updateMarca
  };
  
};

export default postRepository();