import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
// import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
// import DateFnsUtils from '@date-io/date-fns';
// import esLocale from "date-fns/locale/es";
import debounce from 'lodash/debounce'
import cloneDeep from 'lodash/cloneDeep';

import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";

import AsyncSelect from '../global/selectApi';

const useStyles2 = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  tableSpacer: {
    flex: 'initial',
  },
  tableToolbar: {
    justifyContent: 'center'
  },
  jsonContent: {
    position: 'absolute',
    top: 36,
    right: 0,
    left: 0,
  },
  textField: {
    margin: theme.spacing(2, 1),
    width: 200,
  },
  title: {
    flexGrow: 1,
  },
  contentButton:{
    alignSelf: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  buttonExport:{
    backgroundColor: "#24864e",
    '&:hover': {
      backgroundColor: '#206f43'
    }
  },
  toolbar:{
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  contentDate:{
    textAlign: "center",
    '& #outlined-basic': {
      paddingBottom: theme.spacing(1),
    }
  },
}));

const default_state = {
  text_search: "",
  state_id: [],
  stage_id: [],
  country_id: [],
  license_id: [],
  client_type: [],
  date_start_renewals: null,
  end_date_renewals: null,
}

export default function CustomPaginationActionsTable(props) {
  const classes = useStyles2();

  const [selectedDate, setDateChange] = React.useState({});

  const [dataSearch, setDataSearch] = React.useState(default_state);

  const handleDateChange = (name, date)=>{

    let value = null;
    if(date){
      value = date.getFullYear() + '-' +  (date.getMonth() + 1)  + '-' +  date.getDate();
    }

    setDataSearch({...dataSearch, [name]: value });
    setDateChange({...selectedDate, [name]: date });
  }


  const handleSearch = ()=>{
    props.callback(dataSearch);
  }

  const handleChangeSearch = name => value => {
    setDataSearch({...dataSearch, [name]: value});
  }

  const handleChange = name => event => {
    setDataSearch({...dataSearch, [name]: event.target.value});
  };

  return (
    <Toolbar className={classes.toolbar}>

      <Grid container spacing={1}>

        <Grid item xs={2} sm={2}>
          <TextField
            autoFocus
            id="outlined-basic"
            variant="outlined"
            margin="dense"
            fullWidth
            placeholder="Buscar..."
            value={dataSearch.text_search}
            onChange={handleChange('text_search')}
            // onChange={e => {
            //   debounceSingleFieldValidation("text_search", e.target.value)
            // }}
            onKeyUp={e=>{
              if (e.key === 'Enter') {
                e.preventDefault();
                e.stopPropagation();
                handleSearch();
              }
            }}
          />
        </Grid>

        <Grid item xs={2} sm={2}>
          <AsyncSelect
            placeholder="Tipo"
            // defaultValue={{value: "CLIENTE", label: 'Cliente'}}
            select_value={dataSearch.client_type}
            callback={handleChangeSearch('client_type')}
            defaultOptions={true}
            fullWidth
            isClearable={true}
            isPromiseOptions={false}
            isMulti
          />
        </Grid>

        <Grid item xs={2} sm={2}>
          <AsyncSelect
            placeholder="Estado"
            url={`${process.env.REACT_APP_API_URL}/clientstate/`}
            select_value={dataSearch.state_id}
            callback={handleChangeSearch('state_id')}
            defaultOptions={true}
            fullWidth
            isClearable={true}
            isMulti
          />
        </Grid>

        <Grid item xs={2} sm={2}>
          <AsyncSelect
            placeholder="Etapa"
            url={`${process.env.REACT_APP_API_URL}/clientstage/`}
            select_value={dataSearch.stage_id}
            callback={handleChangeSearch('stage_id')}
            defaultOptions={true}
            fullWidth
            isClearable={true}
            isMulti
          />
        </Grid>

        <Grid item xs={2} sm={2}>
          <AsyncSelect
            placeholder="País"
            url={`${process.env.REACT_APP_API_URL}/pais/`}
            select_value={dataSearch.country_id}
            callback={handleChangeSearch('country_id')}
            defaultOptions={false}
            fullWidth
            isClearable={true}
            isMulti
          />
        </Grid>

        <Grid item xs={2} sm={2}>
          <AsyncSelect
            placeholder="Plan"
            url={`${process.env.REACT_APP_API_URL}/licencia/`}
            select_value={dataSearch.license_id}
            callback={handleChangeSearch('license_id')}
            defaultOptions={true}
            isSearchable={false}
            fullWidth
            isClearable={true}
            isMulti
          />
        </Grid>


          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <Grid item xs={2} sm={2}>
                <KeyboardDatePicker
                  autoOk
                  format="yyyy-MM-dd"
                  value={selectedDate.date_start_renewals || null}
                  placeholder="Fecha inicio"
                  inputVariant="outlined"
                  fullWidth
                  margin="dense"
                  onChange={date => handleDateChange("date_start_renewals", date)} 
                />
            </Grid>
            <Grid item xs={2} sm={2}>

                <KeyboardDatePicker
                  autoOk
                  format="yyyy-MM-dd"
                  value={selectedDate.end_date_renewals || null}
                  placeholder="Fecha fin"
                  inputVariant="outlined"
                  fullWidth
                  margin="dense"
                  onChange={date => handleDateChange("end_date_renewals", date)} 
                />  
            </Grid>
          </MuiPickersUtilsProvider>

        <Grid item xs={6} sm={4} className={classes.contentButton}>

          <Button variant="contained" color="primary" onClick={handleSearch}>Buscar</Button>
          <Button variant="outlined" color="primary" onClick={()=>{
            setDateChange({});
            setDataSearch(default_state);
            props.callback(default_state);
          }}>Reiniciar</Button>
          {/* <Button variant="contained" color="primary"
            classes={{containedPrimary: classes.buttonExport }}
            onClick={handleDownloadExcel}
            disabled
          >Exportar</Button> */}
        </Grid>

      </Grid>
    </Toolbar>
  );
}
