import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import debounce from 'lodash/debounce';
import cloneDeep from 'lodash/cloneDeep';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import AsyncSelect from '../global/selectApi';

const default_state = {
	text_search: "",
};

const useStyles2 = makeStyles(theme => ({
  textField: {
    margin: theme.spacing(2, 1),
    width: 200,
  },

  contentButton:{
    alignSelf: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  toolbar:{
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },

}));

const FilterTable = (props)=>{
  const classes = useStyles2();


	const [dataSearch, setDataSearch] = React.useState(default_state);

	React.useEffect(() => {
		props.callback(dataSearch);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

  const handleSearch = ()=>{
    props.callback(dataSearch);
  }

	const handleChange = name => event => {
		setDataSearch({...dataSearch, [name]: event.target.value});
	};

	return (
		<Toolbar className={classes.toolbar}>

			<Grid container spacing={1}>

				<Grid item xs={2} sm={2}>
					<TextField
						autoFocus
						id="outlined-basic"
						variant="outlined"
						margin="dense"
						fullWidth
						placeholder="Buscar..."
						value={dataSearch.text_search}
						onChange={handleChange('text_search')}
						onKeyUp={e=>{
							if (e.key === 'Enter') {
								e.preventDefault();
								e.stopPropagation();
								handleSearch();
							}
						}}
					/>
				</Grid>

				<Grid item xs={6} sm={4} className={classes.contentButton}>
					<Button variant="contained" color="primary" onClick={handleSearch}>Buscar</Button>
					<Button variant="outlined" color="primary" onClick={()=>{
						setDataSearch(default_state);
						props.callback(default_state);
					}}>Reiniciar</Button>
				</Grid>

			</Grid>
		</Toolbar>
	);
}

export default FilterTable;