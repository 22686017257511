import React from 'react'
import Lottie from 'react-lottie';
//import * as animationData from './progress-bar.json'
import * as animationData from './loading.json'

export default class LottieControl extends React.Component {
 
  constructor(props) {
    super(props);
    this.state = {isStopped: false, isPaused: false};
  }
 
  render() {

    const defaultOptions = {
      loop: true,
      autoplay: true, 
      animationData: animationData.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      } 
    };

    const size = this.props.size || 50;

    return (
      <Lottie options={defaultOptions}
        height={size+"%"}
        width={size+"%"}
        isStopped={this.state.isStopped}
        isPaused={this.state.isPaused}/>
    )
  }
}