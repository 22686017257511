import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
// import Checkbox from '@material-ui/core/Checkbox';
// import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
// import DateFnsUtils from '@date-io/date-fns';
// import esLocale from "date-fns/locale/es";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
// import InputAdornment from '@material-ui/core/InputAdornment';

import PhoneIcon from '@material-ui/icons/Phone';
// import SmsIcon from '@material-ui/icons/Sms';
import SmsIcon from '@material-ui/icons/Sms';
import EmailIcon from '@material-ui/icons/Email';
import PeopleIcon from '@material-ui/icons/People';
import DescriptionIcon from '@material-ui/icons/Description';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import Badge from '@material-ui/core/Badge';
// import MailIcon from '@material-ui/icons/Mail';

import Tooltip from '@material-ui/core/Tooltip';

import AsyncSelect from '../global/selectApi';
import ApiPut from '../../api/Put';
import CheckboxRedux from '../global/checkbox_list';
import TextfieldApi from '../global/textfieldApi';
import {currencyFormat} from '../../utils';

const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2),
  },
  jsonContent: {
    background: 'white',
    padding: theme.spacing(1),
    position: 'absolute',
    top: 36,
    right: 0,
    left: 0,
    zIndex: 1,
    width: 'min-content',
  },
  rowSelected:{
    background: '#c5cae9',
    // background: '#bbdefb',
    '&:hover': {
      background: '#9fa8da !important',
      // background: '#90caf9 !important',
    }
  },
  customWidth:{
    width: '180px !important',
    minWidth: '180px !important',
    maxWidth: '180px !important',
  },
  customWidthDate:{
    width: '140px !important',
    minWidth: '140px !important',
    maxWidth: '140px !important',
  },
  contentCustom:{
    'white-space': 'nowrap',
    '& .MuiBadge-root': {
      marginRight: theme.spacing(3),
    },
  },
  customBadge:{
    // width: 'auto',
    background: 'transparent',
    color: 'white',
    border: 'hidden',
    width: '-moz-available',
  },
  // contentValues:{
  //   whiteSpace: 'nowrap',
  //   '& .MuiTextField-root':{
  //     width: 90,
  //     marginRight: theme.spacing(1),
  //   }
  // },
  // contentValuesFocus:{
  //   // background: '#eee',
  //   position: 'relative',
  // },
  contentValues:{
    display: 'flex',
    width: 320,
  },
}));


export default function SimplePopover(props) {

  const [row, setRow] = React.useState(props.row);
  const [focusValues, setFocusValues] = React.useState(false);

  const classes = useStyles();

  const handleChangeState = value => {

    ApiPut.updateMarca(row.id, {
      state_id: value.id,
    })
    .then(function(response) {
    })
    .catch(function(error) {
      console.log("error", error);
    });

  }

  const handleChangeStage = value => {

    ApiPut.updateMarca(row.id, {
      stage_id: value.id,
    })
    .then(function(response) {
    })
    .catch(function(error) {
      console.log("error", error);
    });

  }

  // const handleSaveMarkCustomer = (event) => {

  //   const checked = event.target.checked;

  //   ApiPut.updateMarca(row.id, {
  //     marked: checked,
  //   })
  //   .then(function(response) {
  //     setRow({...row, marked: checked})
  //   })
  //   .catch(function(error) {
  //     console.log("error", error);
  //   });

  // }

  // const handleDateChange = (name, date)=>{

  //   let value = formatDate(date);

  //   if(!value){ return; }

  //   ApiPut.updateMarca(row.id, {
  //     entry_date: value,
  //   })
  //   .then(function(response) {
  //     setRow({...row, entry_date: value})
  //   })
  //   .catch(function(error) {
  //     console.log("error", error);
  //   });

  // }
  
  const getColor = (number, limit = 10) => {
    return number > limit ? 'primary' : 'secondary';
  }

  return (
    // <TableRow hover className={ row.marked && classes.rowSelected }>
    <TableRow hover className={row.marked ? classes.rowSelected : null}>

      <TableCell>
          <CheckboxRedux id={row.id} list_name="renovation_list"/>
      </TableCell>

      <TableCell>{row.nombre}</TableCell>
      <TableCell>{row.registration_expires || "----------"}</TableCell>
      
      {/* <TableCell>{row.id}</TableCell> */}
      {/* <TableCell>{row.nombre}</TableCell> */}

      <TableCell className={classes.customWidth}>
          <AsyncSelect
            placeholder="Estado"
            defaultValue={ row.state ? {
              value: row.state,
              label: row.state_name,
            } : null }
            url={`${process.env.REACT_APP_API_URL}/clientstate/`}
            callback={handleChangeState}
            defaultOptions={true}
            isSearchable={false}
          />
      </TableCell>
      <TableCell className={classes.customWidth}>
          <AsyncSelect
            placeholder="Etapa"
            defaultValue={ row.stage ? {
              value: row.stage,
              label: row.stage_name,
            } : null }
            url={`${process.env.REACT_APP_API_URL}/clientstage/`}
            callback={handleChangeStage}
            defaultOptions={true}
            isSearchable={false}
          />
      </TableCell>

      <TableCell>{row.licencia_nombre}</TableCell>
      <TableCell>{row.ciudad}<br/>{row.pais}</TableCell>
      <TableCell>{row.nombre_contacto}</TableCell>
      <TableCell>{row.celular}<br/>{row.email} </TableCell>
      <TableCell>
        <TextfieldApi
          id={row.id}
          value={row.cuotas}
          nameField="cuotas"
        />
      </TableCell>

      {/* <TableCell>
        <Button size="small" variant="outlined" color="primary">
          Aplicar
        </Button>
      </TableCell>
      <TableCell>
        <Button size="small" variant="outlined" color="primary">
          Notificar
        </Button>
      </TableCell> */}

      {/* <TableCell>
        <div className={`${classes.contentValues} ${focusValues ? classes.contentValuesFocus : null}`}>
          <Tooltip title="Correos">
            <TextField
              inputProps={{
                onChange: ()=>{setFocusValues(true);},
                // onBlur: ()=>{setFocusValues(false);}
              }}
              variant="outlined"
              size="small"
              defaultValue={row.correos}
              color={getColor(row.correos)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon color={getColor(row.correos)}/>
                  </InputAdornment>
                ),
              }}
            />
          </Tooltip>

          <Tooltip title="Mensajes de texto">
            <TextField
              inputProps={{
                onFocus: handleValues,
                onBlur: handleValues
              }}
              variant="outlined"
              size="small"
              defaultValue={row.sms}
              color={getColor(row.sms)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SmsIcon color={getColor(row.sms)}/>
                  </InputAdornment>
                ),
              }}
            />
          </Tooltip>

          <Tooltip title="Llamadas">
            <TextField
              onFocus={handleValues}
              variant="outlined"
              size="small"
              defaultValue={row.llamadas}
              color={getColor(row.llamadas)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIcon color={getColor(row.llamadas)}/>
                  </InputAdornment>
                ),
              }}
            />
          </Tooltip>

          <Tooltip title="Usuarios">
            <TextField
              onFocus={handleValues}
              variant="outlined"
              size="small"
              defaultValue={row.usuarios}
              color={getColor(row.usuarios, 0)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PeopleIcon color={getColor(row.usuarios, 0)}/>
                  </InputAdornment>
                ),
              }}
            />
          </Tooltip>
          
          { focusValues && 
            <Button size="small" variant="outlined"
              color="primary"
              style={{position: 'absolute', background: 'white', marginTop: 4}}
            >
              guardar
            </Button>
          }
        </div>
      </TableCell> */}

      {/* <TableCell className={classes.contentCustom}>
        <Tooltip title="Utilizada">
          <label>{row.size_archivos_actual}</label>
        </Tooltip>
        &nbsp;/&nbsp;
        <Tooltip title="Disponible">
          <label>{row.size_archivos_disponible}</label>
        </Tooltip>
      </TableCell> */}

      <TableCell className={classes.contentCustom}>

        <TextfieldApi
          id={row.id}
          value={row.usuarios}
          nameField="usuarios"
          icon={<PeopleIcon color="action" fontSize="inherit"/>}
          titleTooltip="Usuarios"
        />

        <TextfieldApi
          id={row.id}
          value={row.megas_almacenamiento}
          nameField="megas_almacenamiento"
          icon={<AttachFileIcon color="action" fontSize="inherit"/>}
          titleTooltip="Megas almacenamiento"
          label={row.size_archivos_actual}
        />

        <TextfieldApi
          id={row.id}
          value={row.polizas_vigentes}
          nameField="polizas_vigentes"
          icon={<DescriptionIcon color="action" fontSize="inherit"/>}
          titleTooltip="Pólizas vigentes"
        />

        <TextfieldApi
          id={row.id}
          value={row.sedes}
          nameField="sedes"
          icon={<HomeWorkIcon color="action" fontSize="inherit"/>}
          titleTooltip="Sedes"
        />

        <TextfieldApi
          id={row.id}
          value={row.correos}
          nameField="correos"
          icon={<EmailIcon color="action" fontSize="inherit"/>}
          titleTooltip="Correos"
        />

        <TextfieldApi
          id={row.id}
          value={row.sms}
          nameField="sms"
          icon={<SmsIcon color="action" fontSize="inherit"/>}
          titleTooltip="Mensajes de texto"
        />

        <TextfieldApi
          id={row.id}
          value={row.llamadas}
          nameField="llamadas"
          icon={<PhoneIcon color="action" fontSize="inherit"/>}
          titleTooltip="Llamadas"
        />

      </TableCell>


      {/* Valor licencia anterior en moneda local. */}
      <TableCell>
        { row.tipo_moneda_codigo }
        <br/>
        { row.valor_actual && currencyFormat(row.valor_actual) || (
            <TextfieldApi
              id={row.id}
              value={row.valor_actual}
              nameField="valor_marca_licencia"
          />
        )}
        <br/>
        <small style={{textDecoration: 'line-through'}}>
          { row.valor_anterior && currencyFormat(row.valor_anterior) || ""}
        </small>
      </TableCell>

      {/* Valor renovación en moneda local. */}
      {/* <TableCell>
        <TextField
          // onChange={(e) => handleChangeFe(e.currentTarget.value)}
          style={{minWidth: '50px'}}
          defaultValue={row.cuotas}
          variant="outlined"
          size="small"
        />
      </TableCell> */}

    </TableRow>
  );
}
