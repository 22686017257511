import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '../../components/subscription-link/table';
import FormDialog from '../../components/subscription-link/dialog-form';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2),
  },
  button:{
    margin: theme.spacing(1),
  }
}));

export default function Content() {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [reloadPage, setReloadPage] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
    setReloadPage(false);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleReloadPage(){
    handleClose();
    setReloadPage(true);
  }

  return (
    <Paper className={classes.root}>
      <Button variant="outlined" color="primary" size="small" className={classes.button} onClick={handleClickOpen}>
        Crear link de suscripción
      </Button>
      <Table reloadPage={reloadPage}/>
      { open && <FormDialog open={open} handleClose={handleClose} handleReloadPage={handleReloadPage}/>}
    </Paper>
  );
}
