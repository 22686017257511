import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { Divider } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import cloneDeep from 'lodash/cloneDeep';

import Searchs from './searchs';
import Row from './row';
import CheckboxRedux, { CheckboxReduxLabel } from '../global/checkbox_list';
import GetApi from '../../api/Get';
import { getLocalToken } from "../../utils";
import Lottie from '../../lottie';

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    justifyContent: 'center',
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1);
  }

  return (
    <div className={classes.root}>
      {/* <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton> */}
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      {/* <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton> */}
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  tableSpacer: {
    flex: 'initial',
  },
  tableToolbar: {
    justifyContent: 'center'
  },
  jsonContent: {
    position: 'absolute',
    top: 36,
    right: 0,
    left: 0,
  },
  textField: {
    margin: theme.spacing(2, 1),
    width: 200,
  },
  gridContainer:{
    paddingLeft: theme.spacing(1),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  buttonExport:{
    backgroundColor: "#24864e",
    '&:hover': {
      backgroundColor: '#206f43'
    }
  },
  dateField:{
    marginTop: theme.spacing(2) - 3,
    paddingLeft: theme.spacing(1),
  },
  toolbar:{
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  divider:{
    margin: theme.spacing(1),
  }
}));

// let dataSearch = {};

export default function CustomPaginationActionsTable(props) {
  const classes = useStyles2();

  const { customer_ids } = props;
  
  const rowsPerPage = 10;
  const [dataSearch, setDataSearch] = React.useState({customer_ids});
  
  React.useEffect(() => {
    getList();
  }, [dataSearch]);

  const [data, setData] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const emptyRows = rowsPerPage - (data ? data.results.length : 10);

  function getList(newPage=1){

    setPage(newPage);
    setData(null);
    GetApi.listRenovations({...dataSearch, page: newPage})
    .then( response => {
      setData(response);
    }).catch(e => {
      console.log("e", e);
    });
  }
  
  // React.useEffect(() => {
  //   getList();
  // }, []);
  
  function handleChangePage(event, newPage) {
    getList(newPage + 1);
  }

  React.useEffect(() => {
    if(props.reloadPage){
      getList();
    }
  }, [props.reloadPage]);

  const handleChangeDataSearch = data => {

    const params = {};
    // const params = new URLSearchParams();

    Object.keys(data).map((key, index) =>{
      var value = data[key];
      
      if(Array.isArray(value)){
        value = value.length ? value.map(function(item) {
          return item.id || item.value;
        }) : null;
      }
      
      if(value){
        params[key] = value;
      }
    })
    
    window.location.hash = `?${new URLSearchParams(params).toString()}`;

    setDataSearch(params);
  }
  
  const handleDownloadExcel = (data) => {
    
    let anchor = document.createElement("a");
    document.body.appendChild(anchor);

    let headers = new Headers();
    headers.append('Authorization', 'Token ' + getLocalToken());

    var url = new URL(`${process.env.REACT_APP_API_URL}/marca/`);
        url.searchParams.append("download_excel", true);
    
    Object.keys(dataSearch).find(function(k) {
      
      let value = dataSearch[k];
      let label = k;

      if(Array.isArray(value)){
        value.find((item)=> url.searchParams.append(label+"[]", item) );
      }else if(value){
        url.searchParams.append(label, value);
      }

    });

    fetch(url.toString(), { headers })
    .then(response => response.blob())
    .then(blobby => {
        let objectUrl = window.URL.createObjectURL(blobby);
        anchor.href = objectUrl;
        anchor.download = 'clientes_softseguros.xlsx';
        anchor.click();

        window.URL.revokeObjectURL(objectUrl);
    });
  }

  function handleActionsGetList(data){
    let _dataSearch = cloneDeep(dataSearch);
    
    setData(null);
    GetApi.listRenovations({..._dataSearch, ...data, page})
    .then( response => {
      setData(response);
    }).catch(e => {
      console.log("e", e);
    });
  }

  return (
    <Paper className={classes.root}>

      <Searchs
        callback={handleChangeDataSearch}
        handleDownloadExcel={handleDownloadExcel}
      />

      <Divider className={classes.divider}/>

      <div className={classes.tableWrapper} >

        <CheckboxReduxLabel
          length={data ? data.count : 0}
          handleActionsGetList={handleActionsGetList}
          list_name="renovation_list"
        />
              
        <Table size="small" >
          <TableHead>
            <TableRow>

              <TableCell variant="body">
                <CheckboxRedux list_name="renovation_list" all/>
              </TableCell>

              <TableCell variant="body"><strong>Marca</strong></TableCell>
              <TableCell variant="body"><strong>Vigencia</strong></TableCell>

              <TableCell variant="body"><strong>Estado</strong></TableCell>
              <TableCell variant="body"><strong>Etapa</strong></TableCell>
              <TableCell variant="body"><strong>Plan</strong></TableCell>
              <TableCell variant="body"><strong>País</strong></TableCell>
              <TableCell variant="body"><strong>Nombre contacto</strong></TableCell>
              <TableCell variant="body"><strong>Celular / Correo</strong></TableCell>
              {/* # cuotas,debe ser editable.(predeterminado venir con 1 cuota) */}
              <TableCell variant="body"><strong>Cuotas</strong></TableCell>


              {/* Incremento de renovación */}
              {/* <TableCell variant="body"><strong>Incremento renovación</strong></TableCell> */}

              {/* Botón aplicar incremento: Se debe guardar el valor de cada licencia */}
              {/* <TableCell variant="body"><strong>Aplicar incremento</strong></TableCell> */}

              {/* <TableCell variant="body"><strong>Notificar renovación</strong></TableCell> */}

              {/* Capacidad de almacenamiento. (disponible y utilizada) */}
              {/* <TableCell variant="body"><strong>Capacidad de almacenamiento.</strong></TableCell> */}

              {/* Capacidad asistente virtual (correos, mensajes de texto llamadas) (disponible y utilizada) */}
              <TableCell variant="body" align="center"><strong>Almacenamiento y recursos</strong></TableCell>

              {/* Valor licencia anterior en moneda local. */}
              {/* <TableCell variant="body"><strong>Valor licencia anterior</strong></TableCell> */}

              <TableCell variant="body"><strong>Valor actual</strong></TableCell>

              {/* Valor renovación en moneda local. */}
              {/* <TableCell variant="body"><strong>Valor renovación</strong></TableCell> */}

            </TableRow>
          </TableHead>
          <TableBody>

            {data == null ? (
              <TableRow style={{ height: 61 * 10 }}>
                <TableCell colSpan={14} align='center'>
                  <Lottie size={10}/>
                  {/* Cargando... */}
                </TableCell>
              </TableRow>
            ) : data.results.map( (row, index) => (
              <Row key={index} row={row}/>
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 61 * emptyRows }}>
                <TableCell colSpan={7} />
              </TableRow>
            )}
            

          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                classes={{
                  toolbar: classes.tableToolbar,
                  spacer: classes.tableSpacer
                }}
                colSpan={14}
                rowsPerPageOptions={[10]}

                count={data ? data.count : 0}
                page={data ? ( data.count === 0 ? 0 : page-1 ) : 0}
                
                rowsPerPage={rowsPerPage}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                // onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </Paper>
  );
}
