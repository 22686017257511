import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import Row from './row';
import GetApi from '../../api/Get';

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
    justifyContent: 'center',
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1);
  }

  return (
    <div className={classes.root}>
      {/* <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton> */}
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      {/* <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton> */}
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  tableWrapper: {
    // overflowX: 'auto',
  },
  tableSpacer: {
    flex: 'initial',
  },
  tableToolbar: {
    justifyContent: 'center'
  },
  jsonContent: {
    position: 'absolute',
    top: 36,
    right: 0,
    left: 0,
  }
}));

export default function CustomPaginationActionsTable(props) {
  const classes = useStyles2();
  
  const rowsPerPage = 10;
  const [data, setData] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const emptyRows = rowsPerPage - (data ? data.results.length : 10);
  
  function getList(newPage){
    setPage(newPage);
    setData(null);
    GetApi.listSubscriptionLink(newPage)
    .then(r => {
      setData(r);
    }).catch(e => {
      console.log("e", e);
    });
  }
  
  React.useEffect(() => {
    getList(1);
  }, []);
  
  function handleChangePage(event, newPage) {
    getList(newPage + 1);
  }

  React.useEffect(() => {
    if(props.reloadPage){
      getList(1);
    }
  }, [props.reloadPage]);

  return (
    <Paper className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell variant="body" size="medium"><strong>Marca</strong></TableCell>
              <TableCell variant="body" size="medium"><strong>Licencia Softseguros</strong></TableCell>
              <TableCell variant="body" size="medium"><strong>Plan Epayco</strong></TableCell>
              <TableCell variant="body" size="medium"><strong>Fecha de caducidad y hora</strong></TableCell>
              <TableCell variant="body" size="medium"><strong>Usado</strong></TableCell>
              <TableCell variant="body" size="medium"><strong>Link</strong></TableCell>
              <TableCell variant="body" size="medium"><strong>Opciones</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {data == null ? (
              <TableRow style={{ height: 61 * 10 }}>
                <TableCell colSpan={7} align='center'>Cargando...</TableCell>
              </TableRow>
            ) : data.results.map( (row, index) => (
              <TableRow key={index} hover>
                <Row row={row}/>
              </TableRow>
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 61 * emptyRows }}>
                <TableCell colSpan={7} />
              </TableRow>
            )}
            

          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                classes={{
                  toolbar: classes.tableToolbar,
                  spacer: classes.tableSpacer
                }}
                colSpan={7}
                rowsPerPageOptions={[10]}

                count={data ? data.count : 0}
                page={data ? ( data.count === 0 ? 0 : page-1 ) : 0}

                rowsPerPage={rowsPerPage}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                // onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </Paper>
  );
}
