import { combineReducers } from 'redux';
import checkbox_list from './CheckboxList';
import stage_list from './StageList';
import state_list from './StateList';

// estado inicial de los estados
const initialState = {
	text_to_search: "",
};

// encargando de ir modificando los elementos del estado
function search_projects(state=initialState, action){
	switch(action.type) {
		case "SEARCH_PROJECTS": return {
			...state,
			text_to_search: state.text_to_search
		}
		default: return state // Retornar el estado actual por defecto.
	}
};

export default combineReducers({
	tables: checkbox_list,
	stage_list,
	state_list,
  	search_projects
})

